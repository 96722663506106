<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form ref="formData" :model="formData" label-width="80px" @submit.native.prevent>
                <el-row v-show="!this.disableGrid">
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}{{getTitle}}</strong>
                                    <strong class="pull-right" style=" font-size: 16px;color: red ">{{formData.is_cancel?'已作废':''}}{{titleList}}</strong>
                                </el-col>
                            </el-row>
                        </div>
                        <el-row>
                            <template v-if="isShowSKD">
                                <el-col :lg="5">
                                    <el-radio-group v-model="formData.cash_type" :disabled="formData.is_cancel"
                                                    style=" margin-top: 14px; ">
                                        <el-radio @click.native.prevent="radioEvent('SKD'),isDataChanage = true"
                                                  label="SKD">收款单
                                        </el-radio>
                                        <!--<el-radio label="ZPRZ">支票入账</el-radio>-->
                                        <el-radio @click.native.prevent="radioEvent('ZJRZ'),isDataChanage = true"
                                                  label="ZJRZ">资金入账
                                        </el-radio>
                                        <el-radio @click.native.prevent="radioEvent('SDJ'),isDataChanage = true"
                                                  label="SDJ">收订金
                                        </el-radio>
                                    </el-radio-group>

                                </el-col>
                            </template>
                            <template v-if="isShowFKD">
                                <el-col :lg="4" :sm="12" :md="8">
                                    <el-radio-group @change="radioEvent" v-model="formData.cash_type"
                                                    :disabled="formData.is_cancel"
                                                    style=" margin-top: 14px; ">
                                        <el-radio @click.native.prevent="radioEvent('FKD'),isDataChanage = true"
                                                  label="FKD">付款单
                                        </el-radio>
                                        <el-radio @click.native.prevent="radioEvent('YFK'),isDataChanage = true"
                                                  label="YFK">预付款
                                        </el-radio>
                                    </el-radio-group>
                                </el-col>
                            </template>

                            <el-col :lg="4" :sm="12" :md="8">
                                <el-form-item label="录单日期:">
                                    <el-date-picker
                                            style=" width: 140px"
                                            v-model="formData.cash_date"
                                            :clearable="false"
                                            @blur="cashDateBlur"
                                            :disabled="formData.is_cancel"
                                            @change="isDataChanage = true"
                                            align="right"
                                            type="date"
                                            size="mini"
                                            placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-tooltip class="item" effect="dark" placement="top" :open-delay = "750">
                                    <div slot="content">
                                        单号唯一ID:{{formData.id ? formData.id : '自动生成'}}<br>
                                        单号:{{formData.check ? formData.check : '自动生成'}}
                                    </div>
                                    <el-form-item label="单据编号:">
                                        <el-input v-model="formData.cash_type_id" placeholder="可自动生成"
                                                  @keyup.native.enter="$_focusNext('company_id')"
                                                  :disabled="formData.is_cancel"
                                                  size="mini"></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="记录员:">
                                    <el-input ref="registrar" v-model="formData.registrar" :readonly="true" size="mini"
                                              @keyup.native.enter="$_focusNext('create_date')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-form-item label="创建时间:">
                                    <el-input ref="create_date" v-model="formData.create_date"
                                              placeholder="自动生成" :readonly="true" size="mini"
                                              @keyup.native.enter="$_focusNext('customer')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="修改人:">
                                    <el-input ref="last_name_cash" v-model="formData.last_name_cash"
                                              placeholder="自动生成" :readonly="true" size="mini"
                                              @keyup.native.enter="$_focusNext('bill_type')"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :lg="2" :sm="12" :md="2">
                                <vxe-toolbar class="pull-left" style=" height: 42px; " custom ref="xToolbar1">
                                </vxe-toolbar>
                                <vxe-toolbar class="pull-left" style=" height: 42px; " custom ref="xToolbar2">
                                </vxe-toolbar>
                            </el-col>
                            <template v-if="(isShowSKD || isShowQTSRD) && !isZJRZ">
                                <el-col :lg="4" :sm="12" :md="8">
                                    <el-tooltip class="item" effect="dark" placement="bottom-end" :open-delay = "750">
                                        <div slot="content">
                                            {{formData.company_name}}
                                        </div>
                                        <el-form-item :label="'付款单位'">
                                            <el-select ref="company_id" v-model="formData.company_name"
                                                       style="width: 100%"
                                                       @focus="$_selectorFocusEvent('company_id',formData.company_name)"
                                                       :disabled="formData.is_cancel"
                                                       default-first-option remote :remote-method="$_searchCustomerList"
                                                       clearable placeholder="可搜索查询"
                                                       filterable size="mini"
                                                       @change="isDataChanage = true,companyNameChangeEvent($event,'customer_name'),$_selectorFocusEvent('company_id',formData.company_name)"
                                                       @keyup.native.enter="$_blurNext('company_id'),$_focusNext('handled_by')">
                                                <el-option
                                                        v-for="item in this.$store.getters.getCustomerList"
                                                        :key="item.id"
                                                        :label="item.customer_name"
                                                        :value="item">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-tooltip>
                                </el-col>
                            </template>

                            <template v-if="isShowFKD">
                                <el-col :lg="4" :sm="12" :md="8">
                                    <el-tooltip class="item" effect="dark" placement="bottom-end" :open-delay = "750">
                                        <div slot="content">
                                            {{formData.company_name}}
                                        </div>
                                        <el-form-item :label="'收款单位'">
                                            <el-select ref="company_id" v-model="formData.company_name"
                                                       style="width: 100%"
                                                       :disabled="formData.is_cancel"
                                                       default-first-option remote :remote-method="$_searchSupplierList"
                                                       clearable placeholder="可搜索查询"
                                                       filterable size="mini"
                                                       @change="isDataChanage = true,companyNameChangeEvent($event,'supplier_name')"
                                                       @keyup.native.enter="$_blurNext('company_id'),$_focusNext('handled_by')">
                                                <el-option
                                                        v-for="item in this.$store.getters.getSupplierList"
                                                        :key="item.id"
                                                        :label="item.supplier_name"
                                                        :value="item">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-tooltip>
                                </el-col>
                            </template>
                            <el-col :lg="3" :sm="12" :md="3">
                                <el-form-item label="经手人:">
                                    <el-select ref="handled_by" v-model="formData.handled_by" clearable
                                               placeholder="可搜索查询"
                                               size="mini"
                                               default-first-option
                                               :disabled="formData.is_cancel"
                                               :filter-method="$_handledByListFilter"
                                               @change="isDataChanage = true"
                                               @keyup.native.enter="$_blurNext('handled_by'),$_focusNext('remark_cash')"
                                               filterable>
                                        <el-option
                                                v-for="item in this.$store.getters.getHandledByList"
                                                :key="item.nick_name"
                                                :label="item.nick_name"
                                                :value="item.nick_name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="4">
                                <el-form-item label="单据备注:">
                                    <el-input ref="remark_cash" v-model="formData.remark_cash" size="mini"
                                              :disabled="formData.is_cancel"
                                              @change="isDataChanage = true"
                                              @keyup.native.enter="$_blurNext('remark_cash'),$_focusNext('additional_remark')"></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :lg="4" :sm="12" :md="4">
                                <el-form-item label="附加说明:">
                                    <el-input ref="additional_remark" v-model="formData.additional_remark"
                                              :disabled="formData.is_cancel"
                                              @change="isDataChanage = true"
                                              @keyup.native.enter="$_focusNext('type_id')"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="4">
                                <el-form-item label="修改时间:">
                                    <el-input ref="last_date_cash" v-model="formData.last_date_cash"
                                              placeholder="自动生成" :readonly="true" size="mini"
                                              @keyup.native.enter="$_focusNext('type_id')"></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :lg="3" :sm="12" :md="3">
                                <el-tooltip class="item" effect="dark" placement="top" :open-delay = "750">
                                    <div slot="content">
                                        用于生成会计凭证，读取的凭证号
                                    </div>
                                    <el-form-item label="凭证字:">
                                        <el-input ref="voucher_type_id"
                                                  style="margin-top: 6px"
                                                  @keyup.native.enter="$refs.CashDataxGrid.setActiveCell(tableDataMain[0],'summary_cash_data')"
                                                  :disabled="formData.is_cancel"
                                                  size="mini"
                                                  placeholder="可自动生成" v-model="formData.voucher_type_id">
                                            <el-button @click="changeVoucherTypeId()" slot="append" icon="el-icon-edit"></el-button>
                                        </el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>

                        </el-row>
                    </el-col>
                </el-row>

                <el-row v-show="!this.disableGrid">
                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                ref="CashDataxGrid"
                                align="center"
                                size="mini"
                                :height="this.formData.cash_type == 'SDJ' || this.formData.cash_type == 'YFK'? (this.getViewHeight() - 240)+'':(this.getViewHeight() - 520)+''"
                                highlight-current-row
                                :print-config="{}"
                                @keydown="$_onGridKeydown"
                                @cell-click="cellClickEventMain"
                                @cell-dblclick="cellShowTableEvent"
                                :cell-class-name="cellClassName"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :mouse-config="{selected: true}"
                                :edit-config="{showIcon:true,trigger: 'click', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:false,isArrow: true, isDel: false, isEnter: false, isTab: true,isEdit:!formData.is_cancel,enabled:!formData.is_cancel}">
                            <template #operate="{row,rowIndex}">
                                <el-popover
                                        placement="left-start"
                                        v-model="row.operateVisible"
                                        width="360">
                                    <p>插入行：向上插入一行</p>
                                    <p>复制行：向下复制一行</p>
                                    <div style="text-align: right; margin: 0">
                                        <el-button type="primary" size="mini" @click="row.operateVisible = false,$refs.CashDataxGrid.clearData(row)">清空行</el-button>
                                        <el-button type="primary" size="mini" @click="row.operateVisible = false,$_insertRowEvent(tableDataMain,$refs.CashDataxGrid,row,rowIndex)">插入行</el-button>
                                        <el-button type="primary" size="mini" @click="row.operateVisible = false,$_insertCopyRowEvent(tableDataMain,$refs.CashDataxGrid,row,rowIndex)">复制行</el-button>
                                        <el-button type="primary" size="mini" @click="row.operateVisible = false,$_deleteRowEvent(tableDataMain,$refs.CashDataxGrid,row,rowIndex)">删除行</el-button>
                                        <el-button size="mini" type="text" @click="row.operateVisible = false">取消</el-button>
                                    </div>
                                    <vxe-button size="mini" icon="el-icon-plus" slot="reference" circle></vxe-button>
                                </el-popover>
                            </template>
                            <!-- 摘要查询 提示 -->
                            <template #summary_case_data_edit="{row}">
                                <vxe-pulldown ref="xDownSummary" transfer>
                                    <template #default>
                                        <vxe-input v-model="row.summary_cash_data" clearable suffix-icon="fa fa-search"
                                                   :disabled="formData.is_cancel"
                                                   @keyup="keyUpDropDownEvent(dropDownTableDataSummary,$event,'dropDownGridSummary','xDownSummary','xDownSummaryIsShow')"
                                                   @focus="$_searchSummaryList(row.summary_cash_data),$refs['xDownSummary'].showPanel(),xDownSummaryIsShow=true"
                                                   @blur="xDownSummaryIsShow=false"
                                                   @input="dropDownTablePage.currentPage = 1"
                                                   @change="$_searchSummaryList(row.summary_cash_data),dropDownTablePageSummary.currentPage = 1"></vxe-input>
                                    </template>
                                    <template #dropdown>
                                        <div class="my-dropdown4">
                                            <vxe-grid
                                                    ref="dropDownGridSummary"
                                                    border
                                                    resizable
                                                    show-overflow
                                                    keep-source
                                                    auto-resize
                                                    size="mini"
                                                    height="250"
                                                    :radio-config="{highlight: true,trigger:'row'}"
                                                    :pager-config="dropDownTablePageSummary"
                                                    :data="dropDownTableDataSummary"
                                                    :columns="dropDownTableColumnSummary"
                                                    @radio-change="radioSummaryChangeEvent($event,'xDownSummary')"
                                                    @page-change="pageChangeSummaryEvent">
                                            </vxe-grid>
                                        </div>
                                    </template>
                                </vxe-pulldown>
                            </template>

                            <!-- 科目编码 cashData 查询 提示 -->
                            <!--重要：如果下拉框上下选择，用到getActiveRecord 默认会去掉 隐藏的行，所以隐藏行需要放到最后面-->
                            <template #subject_id_edit="{row}">
                                <vxe-pulldown ref="xDownSubjectId" transfer>
                                    <template #default>
                                        <vxe-input v-model="row.subject_id_cash_data" clearable suffix-icon="fa fa-search"
                                                   :disabled="formData.is_cancel"
                                                   @clear="clearSujectEvent(row)"
                                                   @keyup="keyUpDropDownEvent(dropDownTableData,$event,'dropDownGrid','CashDataxGrid','xDownSubjectId','xDownSubjectIdIsShow')"
                                                   @focus="$_searchSubjectList(row.subject_id_cash_data,'','CashDataxGrid'),$refs.xDownSubjectId.showPanel(),xDownSubjectIdIsShow=true"
                                                   @blur="xDownSubjectIdIsShow=false,$_cheakHasSubject(row)"
                                                   @input="dropDownTablePage.currentPage = 1"
                                                   @change="$_searchSubjectList(row.subject_id_cash_data,'','CashDataxGrid')"></vxe-input>
                                    </template>
                                    <template #dropdown>
                                        <div class="my-dropdown4">
                                            <vxe-grid
                                                    ref="dropDownGrid"
                                                    border
                                                    resizable
                                                    show-overflow
                                                    keep-source
                                                    auto-resize
                                                    size="mini"
                                                    height="250"
                                                    :radio-config="{highlight: true,trigger:'row'}"
                                                    :pager-config="dropDownTablePage"
                                                    :data="dropDownTableData"
                                                    :columns="dropDownTableColumn"
                                                    @cell-click="radioChangeEvent($event,true,'dropDownGrid','CashDataxGrid','xDownSubjectId','xDownSubjectIdIsShow','CashDataxGrid')"
                                                    @radio-change="radioChangeEvent($event,true,'dropDownGrid','CashDataxGrid','xDownSubjectId','xDownSubjectIdIsShow','CashDataxGrid')"
                                                    @page-change="pageChangeEvent">
                                            </vxe-grid>
                                        </div>
                                    </template>
                                </vxe-pulldown>
                            </template>
                            <!-- 金额 -->
                            <template #money_case_data_edit="{row}">
                                <vxe-input
                                        @change="getPaidUp(),getTotalAmount(),getAllocatedAmount(),getDispensAbility()"
                                        v-model="row.money_cash_data" type="float" clearable
                                        :disabled="formData.is_cancel || !$_getActionKeyListBoolean(['/receipt/saveOrUpdateReceipt'])"
                                        :controls="false"></vxe-input>
                            </template>
                            <!-- 冲账金额 -->
                            <template #balance_money_edit="{row}">
                                <vxe-input
                                        @change="getTotalBalanceMoney(),getPaidUp(),getTotalAmount(),getAllocatedAmount(),getDispensAbility()"
                                        :disabled="formData.is_cancel || !$_getActionKeyListBoolean(['/receipt/saveOrUpdateReceipt'])"
                                        v-model="row.balance_money" type="float" clearable
                                        :controls="false"></vxe-input>
                            </template>
                            <!-- 出票单位 -->
                            <template #draw_company_edit="{row}">
                                <el-select ref="draw_company" v-model="row.draw_company"
                                           @focus="$_drawCompanySelectorFocusEvent({row})"
                                           default-first-option no-data-text="没有搜索结果!" placeholder="可搜索查询"
                                           clearable filterable remote
                                           :disabled="formData.is_cancel || !$_getActionKeyListBoolean(['/receipt/saveOrUpdateReceipt'])"
                                           :remote-method="$_searchCustomerAndSupplier">
                                    <el-option v-for="(item,key) in $store.getters.getCustomerAndSupplierList" :key="key"
                                               :label="item.draw_company" :value="item.draw_company">
                                        <span style="margin-right:8px;">{{item.draw_company}}</span>
                                    </el-option>
                                </el-select>
                            </template>

                            <!-- 供应商、客户 -->
                            <template #company_name_edit="{row}">
                                <el-select ref="company_name" v-model="row.company_name"
                                           @focus="$_companyNameSelectorFocusEvent({row})"
                                           default-first-option no-data-text="没有搜索结果!" placeholder="可搜索查询"
                                           clearable filterable remote
                                           :disabled="formData.is_cancel || !$_getActionKeyListBoolean(['/receipt/saveOrUpdateReceipt'])"
                                           :remote-method="$_searchCustomerAndSupplier">
                                    <el-option v-for="(item,key) in $store.getters.getCustomerAndSupplierList" :key="key"
                                               :label="item.draw_company" :value="item.draw_company">
                                        <span style="margin-right:8px;">{{item.draw_company}}</span>
                                    </el-option>
                                </el-select>
                            </template>

                        </vxe-grid>
                        <template v-if="this.formData.cash_type != 'SDJ' && this.formData.cash_type != 'YFK'">

                            <vxe-grid
                                    border
                                    resizable
                                    show-overflow
                                    keep-source
                                    :show-footer="false"
                                    ref="xGridFooter"
                                    align="center"
                                    :footer-method="footerMethod"
                                    :checkbox-config="{range:true}"
                                    :mouse-config="{selected: true}"
                                    size="mini"
                                    height="280"
                                    highlight-current-row
                                    :print-config="{}"
                                    :data="footerData"
                                    :columns="footerTableColumn"
                                    :cell-class-name="cellClassName"
                                    @keydown="$_onGridKeydown"
                                    :edit-config="{showIcon:true,trigger: 'click', mode: 'cell', showStatus: true}"
                                    @cell-click="cellClickEventFooter"
                                    @cell-dblclick="cellClickEvent"
                                    @checkbox-change="checkboxChangeEvent"
                                    @checkbox-all="checkboxChangeEvent"
                                    @checkbox-range-change="checkboxChangeEvent"
                                    :keyboard-config="{enterToTab:false,isArrow: true, isDel: false, isEnter: false, isTab: true,isEdit:!formData.is_cancel,enabled:!formData.is_cancel}">
                                <!-- 供应商、客户 -->
                                <template #company_name_edit="{row}">
                                    <el-select ref="company_name" v-model="row.company_name"
                                               @focus="$_companyNameSelectorFocusEvent({row})"
                                               default-first-option no-data-text="没有搜索结果!" placeholder="可搜索查询"
                                               clearable filterable remote
                                               :disabled="formData.is_cancel || !$_getActionKeyListBoolean(['/receipt/saveOrUpdateReceipt'])"
                                               :remote-method="$_searchCustomerAndSupplier">
                                        <el-option v-for="(item,key) in $store.getters.getCustomerAndSupplierList" :key="key"
                                                   :label="item.draw_company" :value="item.draw_company">
                                            <span style="margin-right:8px;">{{item.draw_company}}</span>
                                        </el-option>
                                    </el-select>
                                </template>
                                <!-- 摘要查询 提示 -->
                                <template #summary_case_data_footer_edit="{row}">
                                    <vxe-pulldown ref="xDownSummaryFooter" transfer>
                                        <template #default>
                                            <vxe-input v-model="row.summary_cash_data" clearable suffix-icon="fa fa-search"
                                                       :disabled="formData.is_cancel || !$_getActionKeyListBoolean(['/receipt/saveOrUpdateReceipt'])"
                                                       @keyup="keyUpDropDownEvent(dropDownTableDataSummary,$event,'dropDownGridSummaryFooter','xDownSummaryFooter','xDownSummaryIsShowFooter')"
                                                       @focus="$_searchSummaryList(row.summary_cash_data),$refs['xDownSummaryFooter'].showPanel(),xDownSummaryIsShowFooter=true"
                                                       @blur="xDownSummaryIsShowFooter=false"
                                                       @input="dropDownTablePage.currentPage = 1"
                                                       @change="$_searchSummaryList(row.summary_cash_data),dropDownTablePageSummary.currentPage = 1"></vxe-input>
                                        </template>
                                        <template #dropdown>
                                            <div class="my-dropdown4">
                                                <vxe-grid
                                                        ref="dropDownGridSummaryFooter"
                                                        border
                                                        resizable
                                                        show-overflow
                                                        keep-source
                                                        auto-resize
                                                        size="mini"
                                                        height="250"
                                                        :radio-config="{highlight: true,trigger:'row'}"
                                                        :pager-config="dropDownTablePageSummary"
                                                        :data="dropDownTableDataSummary"
                                                        :columns="dropDownTableColumnSummary"
                                                        @radio-change="radioSummaryChangeEventFooter"
                                                        @page-change="pageChangeSummaryEvent">
                                                </vxe-grid>
                                            </div>
                                        </template>
                                    </vxe-pulldown>
                                </template>

                                <!-- 出票单位 -->
                                <template #operate="{row,rowIndex}">
                                    <!--<el-popconfirm :title="'您确定要清空第 ' +(rowIndex+1) +' 行？'" @confirm="$refs.xGridFooter.clearData(row)">
                                        <vxe-button size="mini" slot="reference" icon="el-icon-refresh-left" title="清空行" circle></vxe-button>
                                    </el-popconfirm>
                                    <el-popconfirm title="插入该行那个位置？" @confirm="$_insertRowEvent(footerData,$refs.xGridFooter,row,rowIndex)" confirm-button-text='上' cancel-button-text='取消'>
                                        <vxe-button size="mini" style="margin-left: 3px" icon="el-icon-plus" slot="reference" title="插入行" circle></vxe-button>
                                    </el-popconfirm>
                                    <el-popconfirm :title="'您确定要删除第 ' +(rowIndex+1) +' 行？'" @confirm="$_deleteRowEvent(footerData,$refs.xGridFooter,row,rowIndex)">
                                        <vxe-button size="mini" style="margin-left: 3px" icon="el-icon-delete" slot="reference" title="删除行" circle></vxe-button>
                                    </el-popconfirm>-->

                                    <el-popover
                                            placement="left-start"
                                            v-model="row.operateVisible"
                                            width="360">
                                        <p>插入行：向上插入一行</p>
                                        <p>复制行：向下复制一行</p>
                                        <div style="text-align: right; margin: 0">
                                            <el-button type="primary" size="mini" @click="row.operateVisible = false,$refs.xGridFooter.clearData(row)">清空行</el-button>
                                            <el-button type="primary" size="mini" @click="row.operateVisible = false,$_insertRowEvent(footerData,$refs.xGridFooter,row,rowIndex)">插入行</el-button>
                                            <el-button type="primary" size="mini" @click="row.operateVisible = false,$_insertCopyRowEvent(footerData,$refs.xGridFooter,row,rowIndex)">复制行</el-button>
                                            <el-button type="primary" size="mini" @click="row.operateVisible = false,$_deleteRowEvent(footerData,$refs.xGridFooter,row,rowIndex)">删除行</el-button>
                                            <el-button size="mini" type="text" @click="row.operateVisible = false">取消</el-button>
                                        </div>
                                        <vxe-button size="mini" icon="el-icon-plus" slot="reference" circle></vxe-button>
                                    </el-popover>
                                </template>
                                <template #draw_company_edit="{row}">
                                    <el-select ref="draw_company" v-model="row.draw_company"
                                               @focus="$_drawCompanySelectorFocusEvent({row})"
                                               default-first-option no-data-text="没有搜索结果!" placeholder="可搜索查询"
                                               clearable filterable remote
                                               :remote-method="$_searchCustomerAndSupplier">
                                        <el-option v-for="(item,key) in $store.getters.getCustomerAndSupplierList" :key="key"
                                                   :label="item.draw_company" :value="item.draw_company">
                                            <span style="margin-right:8px;">{{item.draw_company}}</span>
                                        </el-option>
                                    </el-select>
                                </template>

                                <!-- 结算金额 -->
                                <template #amount_edit="{row}">
                                    <vxe-input
                                            @change="getPaidUp(),getTotalAmount(),getAllocatedAmount(),getDispensAbility(),getHangMoney(),setBalance(row)"
                                            v-model="row.amount" type="float" clearable
                                            :disabled="formData.is_cancel || !$_getActionKeyListBoolean(['/receipt/saveOrUpdateReceipt'])"
                                            :controls="false"></vxe-input>
                                </template>
                                <!-- 科目查询 footer 提示 -->
                                <!--重要：如果下拉框上下选择，用到getActiveRecord 默认会去掉 隐藏的行，所以隐藏行需要放到最后面-->
                                <template #subject_id_footer_edit="{row}">
                                    <vxe-pulldown ref="xDownSubjectIdFooter" transfer>
                                        <template #default>
                                            <vxe-input v-model="row.subject_id_cash_data" clearable
                                                       suffix-icon="fa fa-search"
                                                       @keyup="keyUpDropDownEvent(dropDownTableData,$event,'dropDownGridFooter','xGridFooter','xDownSubjectIdFooter','xDownSubjectIdIsShowFooter')"
                                                       @focus="$_searchSubjectList(row.subject_id_cash_data),$refs.xDownSubjectIdFooter.showPanel(),xDownSubjectIdIsShowFooter=true"
                                                       @blur="xDownSubjectIdIsShowFooter=false,$_cheakHasSubject(row)"
                                                       :disabled="formData.is_cancel || !$_getActionKeyListBoolean(['/receipt/saveOrUpdateReceipt'])"
                                                       @change="$_searchSubjectList(row.subject_id_cash_data)"></vxe-input>
                                        </template>
                                        <template #dropdown>
                                            <div class="my-dropdown4">
                                                <vxe-grid
                                                        ref="dropDownGridFooter"
                                                        border
                                                        resizable
                                                        show-overflow
                                                        keep-source
                                                        auto-resize
                                                        size="mini"
                                                        height="250"
                                                        :radio-config="{highlight: true,trigger:'row'}"
                                                        :pager-config="dropDownTablePage"
                                                        :data="dropDownTableData"
                                                        :columns="dropDownTableColumn"
                                                        @cell-click="radioChangeEvent($event,true,'xDownSubjectIdFooter','xGridFooter','xDownSubjectIdFooter','xDownSubjectIdIsShowFooter')"
                                                        @radio-change="radioChangeEvent($event,true,'xDownSubjectIdFooter','xGridFooter','xDownSubjectIdFooter','xDownSubjectIdIsShowFooter')"
                                                        @page-change="pageChangeEvent">
                                                </vxe-grid>
                                            </div>
                                        </template>
                                    </vxe-pulldown>
                                </template>
                            </vxe-grid>
                        </template>

                    </el-col>
                </el-row>
                <el-row>
                    <el-col :sm="12" :md="3">
                        <el-form-item label="合计:">
                            <el-input size="mini" :readonly="true"
                                      :value="this.$XEUtils.commafy(formData.total_amount?formData.total_amount:0, {digits: 2})"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="3" :sm="12" :md="8">
                        <el-form-item label="实收:">
                            <el-input size="mini" :readonly="true"
                                      :value="this.$XEUtils.commafy(formData.paid_up ?formData.paid_up :0, {digits: 2})"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="3" :sm="12" :md="8">
                        <el-form-item label="可分配金额:" label-width="100px">
                            <el-input size="mini" :readonly="true"
                                      :value="formData.dispens_ability ?formData.dispens_ability :0"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="3" :sm="12" :md="8">
                        <el-form-item label="已分配金额:" label-width="100px">
                            <el-input size="mini" :readonly="true"
                                      :value="this.$XEUtils.commafy(formData.allocated_amount?formData.allocated_amount:0, {digits: 2})"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="3" :sm="12" :md="8">
                        <el-form-item label="挂数金额:" label-width="100px">
                            <el-input size="mini" :readonly="true"
                                      :value="this.$XEUtils.commafy(formData.hang_money ?formData.hang_money :0, {digits: 2})"
                                      clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="3" :sm="12" :md="8">
                        <el-form-item label="合计冲账:" label-width="100px">
                            <el-input size="mini" :readonly="true"
                                      :value="this.$XEUtils.commafy(formData.total_balance_money ?formData.total_balance_money :0, {digits: 2})"
                                      clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="6" id="openCashTypeId" style=" padding-top: 7px; padding-left: 22px; ">
                        <el-input size="mini" type="number" placeholder="请输入" v-model="openCashTypeId"
                                  class="input-with-select">
                            <template slot="prepend">
                                月份：
                                <el-date-picker
                                        style="width:100px"
                                        size="mini"
                                        :clearable="false"
                                        v-model="formData.year_month"
                                        type="month"
                                        value-format="yyyy-MM-dd"
                                        @change="changeYearMonthEvent()"
                                        placeholder="选择月">
                                </el-date-picker>
                                跳转单据编号：
                            </template>
                            <el-button @click="openCashTypeIdEvent()" slot="append" icon="el-icon-search"></el-button>
                        </el-input>
                    </el-col>
                    <!--<el-col :lg="4" :sm="12" :md="8">
                        <el-form-item label="超收转订金:" label-width="100px">
                            <vxe-input size="mini" @change="getDispensAbility()" v-model="formData.excessToAdvance" min="0" :controls="false" type="float" clearable></vxe-input>
                        </el-form-item>
                    </el-col>-->
                </el-row>
                <el-row>
                    <el-col :sm="12" :md="3">
                        <el-form-item label="所选合计余额:" label-width="100px">
                            <el-input size="mini" :readonly="true"
                                      :value="this.total_balance_check"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="12" :md="3">
                        <!--大写实收金额-->
                        <a style="float: right;margin-right: 5mm">合计：{{$_digitUppercase(formData.total_amount?formData.total_amount:0)}}</a>
                    </el-col>
                    <!--<el-col :lg="2" style="margin-left: 20px">
                        <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini">收款情况
                        </el-button>
                    </el-col>
                    <el-col :lg="2" :sm="12" :md="8">
                        <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini">检查勾单
                        </el-button>
                    </el-col>-->

                    <!--<el-col :lg="2" :sm="12" :md="8">
                        <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini">立即打印
                        </el-button>
                    </el-col>-->
                    <el-col :lg="2" :sm="12" :md="8">
                        <el-button style=" margin-top: 8px;margin-bottom: 8px; " @click="automatic" type="primary"
                                   size="mini">自动分配
                        </el-button>
                    </el-col>
                    <el-col :lg="2" :sm="12" :md="8">
                        <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="success"
                                   @click="newBuild()"
                                   size="mini">新建
                        </el-button>
                    </el-col>

                    <el-col :lg="2" :sm="12" :md="8">
                        <el-button style=" margin-top: 8px;margin-bottom: 8px; " :disabled="this.loading || formData.is_cancel || !$_getActionKeyListBoolean(['/receipt/saveOrUpdateReceipt'])"
                                   type="danger" plain @click="onSubmit"
                                   size="mini">保存
                        </el-button>
                    </el-col>
                    <el-col :lg="2" :sm="12" :md="8">
                        <el-button type="primary" size="mini" @click="goBack()"
                                   style="margin-top: 8px;margin-bottom: 8px; ">返回
                        </el-button>
                    </el-col>
                    <el-col :lg="2" :sm="12" :md="8">
                        <el-button type="primary" size="mini" @click="preBill()"
                                   style="margin-top: 8px;margin-bottom: 8px; ">上一张
                        </el-button>
                    </el-col>
                    <el-col :lg="2" :sm="12" :md="8">
                        <el-button type="primary" size="mini" @click="nextBill()"
                                   style="margin-top: 8px;margin-bottom: 8px; ">下一张
                        </el-button>
                    </el-col>
                    <el-col :lg="2" :sm="12" :md="8">
                        <el-button type="primary" size="mini"
                                   :disabled="formData.is_cancel || this.isBlankVue(formData.id) || !$_getActionKeyListBoolean(['/receipt/setIsCancel'])" @click="setIsCancel()"
                                   style="margin-top: 8px;margin-bottom: 8px; ">{{formData.is_cancel ? '已作废': '作废'}}
                        </el-button>
                    </el-col>
                    <el-col :lg="2" :sm="12" :md="8">
                        <el-button type="primary" size="mini"
                                   :disabled="this.isBlankVue(formData.id)" @click="printReceipt(formData.id)"
                                   style="margin-top: 8px;margin-bottom: 8px; ">打印
                        </el-button>
                    </el-col>
                    <el-col :lg="2" :sm="12" :md="8">
                        <el-button v-if="this.isBlankVue(formData.voucher_type_id)" type="primary" size="mini"
                                   :disabled="this.isBlankVue(formData.id)" @click="showCreateVoucher()"
                                   style="margin-top: 8px;margin-bottom: 8px; ">生成凭证
                        </el-button>
                        <el-button v-if="!this.isBlankVue(formData.voucher_type_id)" type="primary" size="mini"
                                   :disabled="this.isBlankVue(formData.id)" @click="$_openType({path: '/voucher', query: {id : formData.voucher_id}})"
                                   style="margin-top: 8px;margin-bottom: 8px; ">查看凭证
                        </el-button>
                    </el-col>
                    <!-- <el-col :lg="4" :sm="12" :md="8">
                         <el-form-item label="超收转订金(单号):" label-width="130px">
                             <el-input size="mini" :readonly="true" placeholder="自动生成" :value="formData.idSDJ"></el-input>
                         </el-form-item>
                     </el-col>-->
                    <vxe-modal v-model="showAssistModel" :lock-view="false" :mask="false" resize
                               margin-size="-500" width="400" title="辅助核算">
                        <template #default>
                            <el-form>
                                <el-row>
                                    <el-col :lg="18" :md="18">
                                        <el-form-item label="往来单位:">
                                            <el-select v-model="tempCompanyAssistModel" value-key="company_name"
                                                       default-first-option remote
                                                       :remote-method="$_searchAllCompany" clearable filterable
                                                       size="mini"
                                                       placeholder="可搜索查询">
                                                <el-option
                                                        v-for="item in this.$store.getters.getAllCompanyList"
                                                        :key="item.company_uuid"
                                                        :label="item.company_name"
                                                        :value="item">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :lg="4" :sm="4" :md="4">
                                        <el-button style=" margin-top: 8px;margin-bottom: 8px; " @click="$_okSetAssist"
                                                   type="primary" size="mini">确认
                                        </el-button>
                                    </el-col>
                                </el-row>
                            </el-form>
                        </template>
                    </vxe-modal>

                    <vxe-modal v-model="showModel.isShow" :lock-view="false" :mask="false" show-zoom resize
                               margin-size="-500" width="1200" title="销售、进仓(单据)">
                        <template #default>
                            <el-row>
                                <el-col :lg="5" :sm="12" :md="8">
                                    <el-date-picker
                                            style=" margin-bottom: 8px;width: 220px"
                                            v-model="pickerDate"
                                            type="daterange"
                                            size="mini"
                                            :clearable="false"
                                            :picker-options="pickerOptions"
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            align="left">
                                    </el-date-picker>
                                </el-col>
                                <el-col :sm="12" :md="8">
                                    <el-button id="createBill" type="primary" @click="searchWarehouseBillByFees" round
                                               size="mini">搜索查询
                                    </el-button>
                                    <el-button type="primary" @click="rowMain.bill_id_kd_or_jc = '',rowMain.showModel.isShow = false" round
                                               size="mini">清空行内单号
                                    </el-button>
                                </el-col>
                            </el-row>
                            <vxe-grid
                                    border
                                    ref="modalGrid"
                                    show-overflow
                                    resizable
                                    height="450"
                                    size="mini"
                                    highlight-current-row
                                    @cell-dblclick="cellClickEventModal"
                                    :cell-class-name="cellClassNameModal"
                                    :data="tableDataModel"
                                    :mouse-config="{selected: true}"
                                    :pager-config="tableDataModelPage"
                                    :columns="tableColumnModel">
                            </vxe-grid>
                        </template>
                    </vxe-modal>
                </el-row>
                <el-dialog width="420px" title="生成凭证" :visible.sync="createVoucher.isShow">
                        <el-form-item label="凭证字:">
                            <el-input size="mini" v-model="createVoucher.formData.voucher_id"></el-input>
                        </el-form-item>
                        <el-form-item label="往上生成凭证字:" label-width="120px">
                            <el-col :md="15">
                                <el-input size="mini" v-model="createVoucher.formData.upVoucherId"></el-input>
                            </el-col>
                            <el-col :md="7">
                                <el-checkbox style="padding-left: 7px" @change="findLastCode()" v-model="createVoucher.formData.isStart">启动</el-checkbox>
                            </el-col>
                        </el-form-item>
                    <div slot="footer" class="dialog-footer">
                        <el-button @click="createVoucher.isShow = false">取 消</el-button>
                        <el-button type="primary" @click="saveCreateVoucher()">确 定</el-button>
                    </div>
                </el-dialog>
            </el-form>
        </el-main>
    </div>
</template>

<script>
    import XEUtils from 'xe-utils'
    import {filterRender} from "@/utils/gird-filter";


    export default {

        data() {
            return {
                mustSaveMsg:'当前数据已被修改，请先保存，再操作！如果不想保存，按F5刷新当前页！',
                pageType:'出纳单',
                tempCompanyAssistModel: '',//弹窗辅助项目
                tempGrid: '',
                isDataChanage: false,
                tempRow: {},//用于判断是否存在辅助项目，存在则缓存起来当前行，当选中点击确定，直接赋值
                showAssistModel: false,
                rowMain: {},
                createVoucher:{
                    isShow:false,
                    formData:{
                        voucher_id:'',
                        upVoucherId:'',
                        isStart:false,
                    },
                },
                showModel: {
                    isShow: false,
               },
                tableDataModel: [],
                tableDataModelPage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
                    pageSizes: [200, 500, 1000, 2000, 5000]
               },
                filterData: {},
                tableColumnModelColumnsFilterData: '',
                sortData: {b_date:'asc'},
                pickerDate: [new Date(new Date().getTime() - 3600 * 1000 * 24 * 90), new Date()],
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                   },
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', [new Date(), new Date()]);
                       }
                   }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', [date, date]);
                       }
                   }, {
                        text: '最近周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [date, new Date()]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近365天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   }]
               },
                tableColumnModelAll: [
                    {
                        field:'b_id', fixed: 'left', title: '单据编号', width: 160,
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'company_name', fixed: 'left', width: 185, title: '供应商、客户',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'cash_type_name', fixed: 'left', width: 80, title: '类型',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'b_date', width: 100, title: '日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'sales_man', width: 70, title: '业务员',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'total_amount', width: 130, title: '应收、应付(金额)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'practical_total_price', width: 170, title: '(实收)应收、应付(金额)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'payend', width: 80, title: '已结金额', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'kick_back_total', width: 80, title: '佣金', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'kick_back_total_balance', width: 89, title: '佣金(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'pounds_fees', width: 80, title: '磅费', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'pounds_fees_balance', width: 89, title: '磅费(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'wind_cut_fees', width: 80, title: '风割费', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'wind_cut_fees_balance', width: 115, title: '风割费(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'loading_car_fees', width: 80, title: '装车费', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'loading_car_fees_balance', width: 115, title: '装车费(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'transfer_fees', width: 80, title: '中转费', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'transfer_fees_balance', width: 115, title: '中转费(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'freight_fees', width: 80, title: '运费', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'freight_fees_balance', width: 89, title: '运费(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   }, {
                        field:'procedure_fees', width: 80, title: '手续费', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                        },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                        }
                    },
                    {
                        field:'procedure_fees_balance', width: 89, title: '手续费(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                        },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                        }
                    },{
                        field:'other_freight_fees', width: 80, title: '外省运费', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'other_freight_fees_balance', width: 89, title: '外省运费(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'taxes_fees', width: 80, title: '税金', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'taxes_fees_balance', width: 89, title: '税金(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                ],
                tableColumnModel: [],
                tableColumnModelKickBack: [
                    {
                        field:'b_id', fixed: 'left', title: '单据编码', width: 130,
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'company_name', fixed: 'left', width: 185, title: '供应商、客户',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'cash_type_name', fixed: 'left', width: 80, title: '类型',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'b_date', width: 100, title: '日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'sales_man', width: 70, title: '业务员',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },

                    {
                        field:'kick_back_total', width: 80, title: '佣金', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'kick_back_total_balance', width: 89, title: '佣金(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },

                ],//佣金
                tableColumnModelPoundsFees: [
                    {
                        field:'b_id', fixed: 'left', title: '单据编码', width: 130,
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'company_name', fixed: 'left', width: 185, title: '供应商、客户',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'cash_type_name', fixed: 'left', width: 80, title: '类型',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'b_date', width: 100, title: '日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'sales_man', width: 70, title: '业务员',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },


                    {
                        field:'pounds_fees', width: 80, title: '磅费', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'pounds_fees_balance', width: 89, title: '磅费(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                ],//磅费
                tableColumnModelWindCutFees: [
                    {
                        field:'b_id', fixed: 'left', title: '单据编码', width: 130,
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'company_name', fixed: 'left', width: 185, title: '供应商、客户',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'cash_type_name', fixed: 'left', width: 80, title: '类型',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'b_date', width: 100, title: '日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'sales_man', width: 70, title: '业务员',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },

                    {
                        field:'wind_cut_fees', width: 80, title: '风割费', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'wind_cut_fees_balance', width: 115, title: '风割费(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                ],//风割费
                tableColumnModelLoadingCarFees: [
                    {
                        field:'b_id', fixed: 'left', title: '单据编码', width: 130,
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'company_name', fixed: 'left', width: 185, title: '供应商、客户',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'cash_type_name', fixed: 'left', width: 80, title: '类型',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'b_date', width: 100, title: '日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'sales_man', width: 70, title: '业务员',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'loading_car_fees', width: 80, title: '装车费', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'loading_car_fees_balance', width: 115, title: '装车费(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                ],//装车费
                tableColumnModelTransferFees: [
                    {
                        field:'b_id', fixed: 'left', title: '单据编码', width: 130,
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'company_name', fixed: 'left', width: 185, title: '供应商、客户',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'cash_type_name', fixed: 'left', width: 80, title: '类型',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'b_date', width: 100, title: '日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'sales_man', width: 70, title: '业务员',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },

                    {
                        field:'transfer_fees', width: 80, title: '中转费', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'transfer_fees_balance', width: 115, title: '中转费(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                ],//中转费
                tableColumnModelFreightFees: [
                    {
                        field:'b_id', fixed: 'left', title: '单据编码', width: 130,
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'company_name', fixed: 'left', width: 185, title: '供应商、客户',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'cash_type_name', fixed: 'left', width: 80, title: '类型',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'b_date', width: 100, title: '日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'sales_man', width: 70, title: '业务员',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },

                    {
                        field:'freight_fees', width: 80, title: '运费', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'freight_fees_balance', width: 89, title: '运费(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'procedure_fees', width: 80, title: '手续费', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                        },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                        }
                    },
                    {
                        field:'procedure_fees_balance', width: 89, title: '手续费(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                        },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                        }
                    },
                    {
                        field:'other_freight_fees', width: 80, title: '外省运费', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'other_freight_fees_balance', width: 89, title: '外省运费(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },

                ],
                //运费
                tableColumnModelTaxesFees: [
                    {
                        field:'b_id', fixed: 'left', title: '单据编码', width: 130,
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'company_name', fixed: 'left', width: 185, title: '供应商、客户',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'cash_type_name', fixed: 'left', width: 80, title: '类型',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'b_date', width: 100, title: '日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'sales_man', width: 70, title: '业务员',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'taxes_fees', width: 80, title: '税金', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                    {
                        field:'taxes_fees_balance', width: 89, title: '税金(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                       }
                   },
                ],
                //手续费
                tableColumnModelProcedureFees: [
                    {
                        field:'b_id', fixed: 'left', title: '单据编码', width: 130,
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                        }
                    },
                    {
                        field:'company_name', fixed: 'left', width: 185, title: '供应商、客户',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                        }
                    },
                    {
                        field:'cash_type_name', fixed: 'left', width: 80, title: '类型',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                        }
                    },
                    {
                        field:'b_date', width: 100, title: '日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                        },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                        }
                    },
                    {
                        field:'sales_man', width: 70, title: '业务员',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                        }
                    },
                    {
                        field:'procedure_fees', width: 80, title: '手续费', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                        },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                        }
                    },
                    {
                        field:'procedure_fees_balance', width: 89, title: '手续费(未付)', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                        },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/receipt/searchHeardBYWarehouseBillByFees', this.pickerDate)
                        }
                    },
                ],
                //税金
                openCashTypeId: '',//用于跳转的单号
                disableGrid: false,
                xDownSummaryIsShow: false,
                xDownSummaryIsShowFooter: false,
                xDownSubjectIdIsShow: false,
                xDownSubjectIdIsShowFooter: false,
                loading: false,
                dropDownTableColumn: [
                    {type: 'radio', title: '选择', width: 40},
                    {field:'id_subject', title: '科目编码', width: 90},
                    {field:'subject_name', title: '科目名称', width: 260},
                    {field:'fater_name', title: '上级科目', width: 210},
                ],
                dropDownTableData: [],
                dropDownTablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
                    pageSizes: [5, 10, 20, 50, 100, 200, 500]
               },
                dropDownTableColumnSummary: [
                    {type: 'radio', title: '选择', width: 40},
                    {field:'id', title: '编码', width: 100},
                    {field:'summary_name', title: '常用摘要', width: 220},
                ],
                //摘要
                dropDownTableDataSummary: [],
                dropDownTablePageSummary: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
                    pageSizes: [5, 10, 20, 50, 100, 200, 500]
               },
                titleList:[],
                total_balance_check:0,
                formData: {
                    id: '',//收款单 ID
                    voucher_type_id: '',//凭证字
                    voucher_id: '',//凭证字
                    year_month : new Date(),//年月
                    year: '',//年份
                    month: '',//月份
                    type: 'SKD',//收款单类型 SKD、FKD
                    total_amount: 0,//合计
                    paid_up : 0,//实收金额
                    total_balance_money : 0,//合计冲账金额 凭证折扣
                    hang_money : 0,
                    balance: 0,
                    dispens_ability : '',//可分配金额
                    allocated_amount: '',//已分配金额
                    cash_type_id : '',//收款单对应不同类型的 ID
                    cash_type : 'SKD',//收款单类型 SKD、ZPRZ、ZJRZ、SDD
                    cash_date: new Date(),//录单日期
                    handled_by: '',//经手人
                    company_name : '',//付款单位
                    company_id : '',//付款单位Id
                    remark_cash: '',//备注
                    registrar: '',//记录人
                    additional_remark: '',//附加说明
                    last_name_cash: '',//最后修改人
                    last_date_cash: '',//最后修改日期
                    create_date: '',//创建日期
                    is_cancel: false,//作废
                    is_delete : false,//删除
                    type_id : '',//凭证字
                    //excessToAdvance: '',//超收转订金
                    //idSDJ: '',//超收转订金(单号)
               },
                start_date: '',
                tableColumn: [
                    {type: 'seq', title: '序号', width: 40},
                    {
                        field:'summary_cash_data',
                        width: 122,
                        title: '摘要',
                        slots: {edit: 'summary_case_data_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {
                        field:'subject_id_cash_data',
                        width: 122,
                        title: '科目编码',
                        slots: {edit: 'subject_id_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {
                        field:'subject_name_cash_data',
                        width: 260,
                        className: 'unEditor',
                        title: '科目名称'
                   },
                    {
                        field:'money_cash_data',
                        width: 122,
                        title: '金额',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       },
                        slots: {edit: 'money_case_data_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   }, {
                        field:'company_name_assist',
                        width: 100,
                        className: 'unEditor',
                        title: '辅助核算'
                   },
                    {
                        field:'company_name',
                        visible: false,
                        slots: {edit: 'company_name_edit'},
                        editRender: {autofocus: 'input.el-input__inner'},
                        width: 180, title: '供应商、客户'
                   },
                    {field:'bill_id_kd_or_jc', width: 160, title: '销售、进仓（单号）'},
                    {
                        field:'balance_money',
                        width: 120,
                        title: '冲账金额',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                        },
                        slots: {edit: 'balance_money_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                    }, {field:'cash_data_remark', title: '备注',width: 220, editRender: {name: 'input'}},
                    {
                        field:'check_number',
                        width: 120,
                        title: '支票号码',
                        editRender: {name: 'ElInput', props: {controls: false}}
                   },
                    {
                        field:'check_date',
                        width: 120,
                        title: '支票日期',
                        editRender: {name: '$input', props: {type: 'date'}},
                   },
                    {
                        field:'draw_company',
                        width: 150,
                        title: '出票单位',
                        slots: {edit: 'draw_company_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },


                    {field:'operate',title: '操作',fixed:'right' , width: 35, slots: {default: 'operate'}}
                ],
                tableColumnYBFYD: [
                    {type: 'seq', title: '序号', width: 40},
                    {
                        field:'summary_cash_data',
                        width: 122,
                        title: '摘要',
                        slots: {edit: 'summary_case_data_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {
                        field:'subject_id_cash_data',
                        width: 122,
                        title: '科目编码',
                        slots: {edit: 'subject_id_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {
                        field:'subject_name_cash_data',
                        width: 260,
                        className: 'unEditor',
                        title: '科目名称'
                   },
                    {
                        field:'money_cash_data',
                        width: 122,
                        title: '金额',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       },
                        slots: {edit: 'money_case_data_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },{
                        field:'balance_money',
                        width: 120,
                        title: '冲账金额',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       },
                        slots: {edit: 'balance_money_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   }, {
                        field:'company_name_assist',
                        width: 100,
                        className: 'unEditor',
                        title: '辅助核算'
                   },
                    {
                        field:'company_name',
                        slots: {edit: 'company_name_edit'},
                        editRender: {autofocus: 'input.el-input__inner'},
                        width: 180, title: '供应商、客户'
                   },
                    {field:'bill_id_kd_or_jc', width: 160, title: '销售、进仓（单号）'},
                    {field:'cash_data_remark', width: 150, title: '备注', editRender: {name: 'input'}},
                    {field:'sales_man', width: 80, className: 'unEditor', title: '业务员'},
                    {
                        field:'fees_name',width: 150,
                        titleHelp: {message: '例如：过磅费：\n则会修改对应销售单已付过磅费的金额\n（跟入的科目没关系）'},
                        className: 'unEditor',
                        title: '销售、进仓（费用名称）'
                   },
                    {field:'company_uuid', visible: false, width: 150, className: 'unEditor', title: 'UUID'},
                    {field:'operate',title: '操作',fixed:'right' , width: 35, slots: {default: 'operate'}}
                ],
                tableDataMain: [],
                footerData: [],
                footerTableColumn: [],
                footerTableColumnSKD: [
                    {type: 'checkbox',title: '全选', width: 50},
                    {type: 'seq',title: '序号', width: 40},
                    {
                        field:'bill_id_kd_or_jc',titleHelp: {message: '双击跳转该单号'}, width: 160, title: '单据编号', formatter: ({row}) => {
                            if (row.cash_type == 'SKD' || row.cash_type == 'FKD') {
                                return row.bill_id_kd_or_jc
                           } else if (row.cash_type == 'SDJ' || row.cash_type == 'YFK') {
                                return row.cash_type_id;
                           } else {
                                return '未知异常'
                           }
                       }
                   },
                    {
                        field:'cash_type',className: 'unEditor', width: 122, title: '单据类型', formatter: ({cellValue,row}) => {
                            if (cellValue == 'SKD') {
                                if(row.bill_id_kd_or_jc.indexOf('QCYS') > -1){
                                    return '期初客户应收'
                               }else{
                                    return '销售单产生应收'
                               }
                           } else if (cellValue == 'SDJ') {
                                return '订金产生应收'
                           } else if (cellValue == 'FKD') {
                                if(row.bill_id_kd_or_jc.indexOf('QCYF') > -1){
                                    return '期初应付'
                               }else{
                                    return '外购单产生应付'
                               }

                           } else if (cellValue == 'YFK') {
                                return '预付款产生应付'
                           } else {
                                return '未知异常'
                           }
                       }
                   },
                    {field:'remark',className: 'unEditor', width: 150, title: '订单备注'},
                    {
                        field:'cash_date',className: 'unEditor', width: 105, title: '开单日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       }
                   },
                    {
                        field:'registrar',className: 'unEditor',
                        width: 70,
                        title: '记录员'
                   },
                    {
                        field:'sales_man',className: 'unEditor',
                        width: 70,
                        title: '业务员'
                   },
                    {
                        field:'total_amount',className: 'unEditor',
                        width: 120,
                        title: '金额',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue, {digits: 2})
                       }
                   },
                    {
                        field:'balance',className: 'unEditor',
                        width: 120,
                        title: '余额',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       }
                   },
                    {
                        field:'amount',
                        width: 120,
                        title: '结算金额',
                        slots: {edit: 'amount_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {
                        field:'isOk',
                        width: 70,
                        title: '确认',
                        formatter: ({row}) => {
                            if (row.balance == 0) {
                                return '✓'
                           } else {
                                return ''
                           }
                       },
                   }, {
                        field:'amount_other',className: 'unEditor',
                        width: 110,
                        visible: false,
                        title: '其他单已结算金额',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                   },{
                        field:'payend',className: 'unEditor',
                        width: 80,
                        visible: false,
                        title: '已支付',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       }
                   },
                    {
                        field:'amount_this',className: 'unEditor',
                        width: 90,
                        visible: false,
                        title: '本单已结算金额',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                   }, {
                        field:'hang_money_this',className: 'unEditor',
                        width: 80,
                        visible: false,
                        title: '本单挂数金额',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       },
                   },
                    {field:'id', width: 55, title: 'id',className: 'unEditor', visible: false},
                    {field:'cash_type_id', width: 125, title: 'cash_type_id',className: 'unEditor', visible: false},
                ],
                //支票入账，暂时废弃
                footerTableColumnZPRZ: [
                    {type: 'seq', title: '序号', width: 40},
                    {field:'check_number', width: 150, title: '支票号码', editRender: {name: 'input'}},
                    {field:'check_type', width: 150, title: '类型', editRender: {name: 'input'}},
                    {
                        field:'draw_company', width: 150, title: '出票单位',
                        slots: {edit: 'draw_company_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field:'amount',
                        width: 120,
                        title: '支票金额',
                        slots: {edit: 'amount_edit'},
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       },
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                ],
                //资金入账、其他收入单
                footerTableColumnZJRZorQTSRDorYBFYD: [
                    {type: 'seq', title: '序号', width: 40},
                    {
                        field:'summary_cash_data',
                        width: 122,
                        title: '摘要',
                        slots: {edit: 'summary_case_data_footer_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {
                        field:'subject_id_cash_data', title: '科目编码',
                        slots: {edit: 'subject_id_footer_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}, width: 90
                   },
                    {field:'subject_name_cash_data', title: '科目名称', className: 'unEditor', width: 260},
                    {
                        field:'amount',
                        width: 120,
                        title: '金额',
                        slots: {edit: 'amount_edit'},
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       },
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {field:'check_number', width: 150, title: '支票号码', editRender: {name: 'input'}},
                    {
                        field:'draw_company', width: 150, title: '出票单位',
                        slots: {edit: 'draw_company_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field:'company_name_assist',
                        width: 100,
                        className: 'unEditor',
                        title: '辅助核算'
                   },
                    {
                        field:'company_name',
                        slots: {edit: 'company_name_edit'},
                        editRender: {autofocus: 'input.el-input__inner'},
                        width: 180, title: '供应商、客户'
                    },
                    {field:'remark', width: 210, title: '备注', editRender: {name: 'input'}},
                    {field:'id', visible: false, width: 55, title: 'id'},
                    {field:'operate',title: '操作',fixed:'right' , width: 35, slots: {default: 'operate'}}
                ],
                footerTableColumnSDJ: [],
                footerTableColumnFKD: [
                    {type: 'checkbox', title: '全选', width: 50},
                    {type: 'seq', title: '序号', width: 40},
                    {field:'bill_id_kd',titleHelp: {message: '双击跳转该单号'},  width: 160, title: '单据编号'},
                    {
                        field:'cash_type',width: 122,className: 'unEditor', title: '单据类型'
                   },
                    {field:'remark_warehouse_bill', width: 150,className: 'unEditor', title: '订单备注'},
                    {
                        field:'bill_date_kd', width: 120,className: 'unEditor', title: '开单日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       }
                   },
                    {
                        field:'registrar',className: 'unEditor',
                        width: 70,
                        title: '记录员'
                   },
                    {
                        field:'sales_man',className: 'unEditor',
                        width: 70,
                        title: '业务员'
                   },
                    {
                        field:'total_amount',className: 'unEditor',
                        width: 120,
                        title: '金额',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue, {digits: 2})
                       }
                   },
                    {
                        field:'balance',className: 'unEditor',
                        width: 120,
                        title: '余额',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       }
                   },
                    {
                        field:'payend',className: 'unEditor',
                        width: 120,
                        visible: true,
                        title: '已支付',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       }
                   },

                    {
                        field:'amount',
                        width: 120,
                        title: '结算金额',
                        slots: {edit: 'amount_edit'},
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : 0, {digits: 2})
                       }, editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {
                        field:'isOk',
                        width: 120,
                        title: '确认',
                        formatter: ({row}) => {
                            if (row.balance == 0) {
                                return '✓'
                           } else {
                                return ''
                           }
                       },
                   }
                ],
                activeName: 'first',
                checked: false,
           }
       },
        methods: {
            changeVoucherTypeId(){
                this.$axios({
                    method: 'post',
                    url: '/admin/receipt/changeVoucherTypeId',
                    data: {
                        formData: this.formData,
                    }
                }).then((response) => {         //这里使用了ES6的语法
                    if (response.status == 200 && response.data.state == "ok") {
                        this.$message({
                            showClose: true,
                            message: '修改成功',
                            type: 'success'
                        });
                        this.searchReceipt();
                    } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                        return
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            showCreateVoucher(){
                this.createVoucher.formData.upVoucherId = this.$store.getters.getCreateVoucherUpVoucherId
                this.createVoucher.formData.isStart = this.$store.getters.getCreateVoucherIsStart
                if(this.isBlankVue(this.createVoucher.formData.isStart)){
                    this.createVoucher.formData.isStart = false
                }
                this.findLastCode();
            },
            findLastCode(){
                //查询单号
                this.$axios({
                    method: 'post',
                    url: '/admin/receipt/searchVoucherId',
                    data: {
                        upVoucherId: this.createVoucher.formData.upVoucherId,
                        isStart: this.createVoucher.formData.isStart,
                        cash_id: this.formData.id,
                    }
                }).then((response) => {         //这里使用了ES6的语法
                    if (response.status == 200 && response.data.state == "ok") {
                        this.createVoucher.formData.voucher_id = response.data.lastCode
                        this.createVoucher.isShow = true
                    } else {
                        this.createVoucher.isShow = true
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                        return
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            saveCreateVoucher(){
                this.$store.dispatch('asyncUpdateCreateVoucherUpVoucherId', this.createVoucher.formData.upVoucherId)
                this.$store.dispatch('asyncUpdateCreateVoucherIsStart', this.createVoucher.formData.isStart)
                this.$_batchCreateVoucher(false,this.formData.id,this.createVoucher.formData.voucher_id)
                this.createVoucher.isShow = false

            },
            printReceipt(id){
                this.$router.push({path: '/printReceipt', query: {id: id}})
           },
            //查询供应商 收款单位
            searchWarehouseBillByFees() {
                this.$axios({
                    method: 'post',
                    url: '/admin/receipt/searchWarehouseBillByFees',
                    data: {
                        currentPage: this.tableDataModelPage.currentPage,
                        pageSize: this.tableDataModelPage.pageSize,
                        sortData: this.sortData,
                        tableColumnModelColumnsFilterData: this.tableColumnModelColumnsFilterData,
                        filterData: this.filterData,
                        pickerDate: this.pickerDate,
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200 && response.data.state == "ok") {
                        this.tableDataModel = response.data.page.list
                        this.tableDataModelPage.pageSize = response.data.page.pageSize
                        this.tableDataModelPage.total = response.data.page.totalRow
                        this.tableDataModelPage.currentPage = response.data.page.pageNumber
                        //console.log(response)
                   } else {
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            //弹出费用筛选单
            cellShowTableEvent({row, column}) {
                if (column.property == 'bill_id_kd_or_jc') {
                    //弹出一个table
                    //console.log(row, column);
                    this.showModel.isShow = true;

                    this.rowMain = row;
                    if ('SKD' == this.formData.cash_type){
                        this.filterData = {
                            company_name :this.formData.company_name,
                       }
                   }else{
                        this.filterData = {
                            company_name :row.company_name,
                       }
                   }
                    if (!this.isBlankVue(row.subject_name_cash_data) && (row.subject_name_cash_data +'').indexOf('佣金')  > -1){
                        this.tableColumnModel = this.tableColumnModelKickBack;
                        this.tableColumnModelColumnsFilterData = 'kick_back_total';
                   }else if (!this.isBlankVue(row.subject_name_cash_data) && (row.subject_name_cash_data +'').indexOf('磅费') > -1){
                        this.tableColumnModel = this.tableColumnModelPoundsFees;
                        this.tableColumnModelColumnsFilterData = 'pounds_fees';
                   }else if (!this.isBlankVue(row.subject_name_cash_data) && (row.subject_name_cash_data +'').indexOf('风割费') > -1){
                        this.tableColumnModel = this.tableColumnModelWindCutFees;
                        this.tableColumnModelColumnsFilterData = 'wind_cut_fees';
                   }else if (!this.isBlankVue(row.subject_name_cash_data) && (row.subject_name_cash_data +'').indexOf('装车费') > -1){
                        this.tableColumnModel = this.tableColumnModelLoadingCarFees;
                        this.tableColumnModelColumnsFilterData = 'loading_car_fees';
                   }else if (!this.isBlankVue(row.subject_name_cash_data) && (row.subject_name_cash_data +'').indexOf('中转费') > -1){
                        this.tableColumnModel = this.tableColumnModelTransferFees;
                        this.tableColumnModelColumnsFilterData = 'transfer_fees';
                   }else if (!this.isBlankVue(row.subject_name_cash_data) && (row.subject_name_cash_data +'').indexOf('运费') > -1){
                        this.tableColumnModel = this.tableColumnModelFreightFees;
                        this.tableColumnModelColumnsFilterData = 'freight_fees';
                    }else if (!this.isBlankVue(row.subject_name_cash_data) && (row.subject_name_cash_data +'').indexOf('手续费') > -1){
                        this.tableColumnModel = this.tableColumnModelProcedureFees;
                        this.tableColumnModelColumnsFilterData = 'procedure_fees';
                    }else if (!this.isBlankVue(row.subject_name_cash_data) && (row.subject_name_cash_data +'').indexOf('税金') > -1){
                        this.tableColumnModel = this.tableColumnModelTaxesFees;
                        this.tableColumnModelColumnsFilterData = 'taxes_fees';
                   }else{
                        this.tableColumnModel = this.tableColumnModelAll;
                        this.tableColumnModelColumnsFilterData = '';
                   }
                    this.searchWarehouseBillByFees();
               }

           },
            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.$XEUtils.add(count, item[field]);
               })
                return count ? this.$XEUtils.round(count, 2) : 0
           },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if (column.property == 'amount' || column.property == 'payend' || column.property == 'balance' || column.property == 'total_amount') {
                            sums.push(this.$XEUtils.commafy(this.sumNum(data, column.property), {digits: 2}))
                       } else {
                            sums.push('')
                       }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },
            cellClassName({row, column}) {
                if (column.property === 'bill_id_kd_or_jc') {
                    if (!this.isBlankVue(row.bill_id_kd_or_jc)) {
                        return 'col-blue'
                   } else {
                        return 'col-fail'
                   }
               }
           },
            cellClassNameModal({row, column}) {
                if (column.property === 'kick_back_total_balance') {
                    if (row.kick_back_total_balance != 0) {
                        return 'col-fail'
                   }
               }
                if (column.property === 'pounds_fees_balance') {
                    if (row.pounds_fees_balance != 0) {
                        return 'col-fail'
                   }
               }
                if (column.property === 'wind_cut_fees_balance') {
                    if (row.wind_cut_fees_balance != 0) {
                        return 'col-fail'
                   }
               }
                if (column.property === 'loading_car_fees_balance') {
                    if (row.loading_car_fees_balance != 0) {
                        return 'col-fail'
                   }
               }
                if (column.property === 'transfer_fees_balance') {
                    if (row.transfer_fees_balance != 0) {
                        return 'col-fail'
                   }
               }
                if (column.property === 'freight_fees_balance') {
                    if (row.freight_fees_balance != 0) {
                        return 'col-fail'
                   }
               }
                if (column.property === 'procedure_fees_balance') {
                    if (row.procedure_fees_balance != 0) {
                        return 'col-fail'
                    }
                }
                if (column.property === 'other_freight_fees_balance') {
                    if (row.other_freight_fees_balance != 0) {
                        return 'col-fail'
                   }
               }

                if (column.property === 'taxes_fees_balance') {
                    if (row.taxes_fees_balance != 0) {
                        return 'col-fail'
                   }
               }
           },
            //修改日期
            changeYearMonthEvent() {
                if (!this.isBlankVue(this.formData.year_month)) {

                    this.formData.year = new Date(this.formData.year_month).getFullYear();
                    this.formData.month = (new Date(this.formData.year_month).getMonth() + 1);
                    //console.log(this.formData.year_month, "year: ", this.formData.year, " month: ", this.formData.month);
               }
           },
            //跳转单据
            openCashTypeIdEvent() {
                if (this.openCashTypeId == '' || this.openCashTypeId == null) {
                    this.$XModal.alert({content: '跳转单号不能为空！', maskClosable: true})
               } else {
                    this.$router.push({
                        path: '/receipt',
                        query: {
                            cash_type_id : this.openCashTypeId,
                            type: this.formData.type,
                            year: this.formData.year,
                            month: this.formData.month
                       }
                   })
               }
           },
            //作废
            setIsCancel() {
                if (this.formData.id != null && this.formData.id != '') {
                    if (this.$_isGridDataChange(this.$refs['CashDataxGrid']) || this.$_isGridDataChange(this.$refs['xGridFooter']) || this.isDataChanage) {
                        this.$message({
                            showClose: true,
                            message: '当前数据已被修改，请先保存，再作废！',
                            type: 'error'
                       });
                   } else {
                        this.$XModal.confirm("作废后将：不能修改，不能反作废。").then(type => {
                            //const $table = this.$refs.xTable
                            if (type === 'confirm') {
                                this.$axios({
                                    method: 'post',
                                    url: '/admin/receipt/setIsCancel',
                                    data: {
                                        cash_id: this.formData.id,
                                        footerData: this.footerData
                                   }
                               }).then((response) => {         //这里使用了ES6的语法
                                    //console.log(response)       //请求成功返回的数据
                                    if (response.status === 200 && response.data.state == 'ok') {
                                        this.$message({
                                            showClose: true,
                                            message: '保存成功',
                                            type: 'success'
                                       });
                                        this.$router.push({
                                            path: '/receipt',
                                            query: {
                                                id: this.formData.id,
                                           }
                                       })
                                   } else {
                                        this.$message({
                                            showClose: true,
                                            message: response.data.msg,
                                            type: 'error'
                                       });
                                        return
                                   }
                               }).catch((error) => {
                                    this.$message({
                                        showClose: true,
                                        message: error,
                                        type: 'error'
                                   });
                                    return
                               });
                           }
                       });

                   }
               } else {
                    this.$message({
                        showClose: true,
                        message: '作废前，请先保存单据，单号不能为空！',
                        type: 'error'
                   });
               }

           },
            //下一张
            nextBill() {
                if (this.$_isGridDataChange(this.$refs['CashDataxGrid']) || this.$_isGridDataChange(this.$refs['xGridFooter']) || this.isDataChanage) {
                    this.$message({
                        showClose: true,
                        message: this.mustSaveMsg,
                        type: 'error'
                    });
                }else if (this.formData.cash_type_id == '' || this.formData.cash_type_id == null) {
                    this.$router.push({
                        path: '/receipt',
                        query: {
                            cash_type_id : 1,
                            type: this.formData.type,
                            year: this.formData.year,
                            month: this.formData.month
                       }
                   })
               } else {
                    let i = this.$XEUtils.add(Number(this.formData.cash_type_id), 1);
                    this.$router.push({
                        path: '/receipt',
                        query: {
                            cash_type_id : i,
                            type: this.formData.type,
                            year: this.formData.year,
                            month: this.formData.month
                       }
                   })
               }
           },
            goBack(){
                if (this.$_isGridDataChange(this.$refs['CashDataxGrid']) || this.$_isGridDataChange(this.$refs['xGridFooter']) || this.isDataChanage) {
                    this.$message({
                        showClose: true,
                        message: this.mustSaveMsg,
                        type: 'error'
                    });
                }else{
                    this.$router.back()
                }
            },
            //上一张
            preBill() {
                if (this.$_isGridDataChange(this.$refs['CashDataxGrid']) || this.$_isGridDataChange(this.$refs['xGridFooter']) || this.isDataChanage) {
                    this.$message({
                        showClose: true,
                        message: this.mustSaveMsg,
                        type: 'error'
                    });
                }else if (this.formData.cash_type_id == '' || this.formData.cash_type_id == null) {
                    this.$router.push({
                        path: '/receipt',
                        query: {
                            cash_type_id : 1,
                            type: this.formData.type,
                            year: this.formData.year,
                            month: this.formData.month
                       }
                   })
               } else {
                    let i = this.$XEUtils.subtract(Number(this.formData.cash_type_id), 1);
                    this.$router.push({
                        path: '/receipt',
                        query: {
                            cash_type_id : i,
                            type: this.formData.type,
                            year: this.formData.year,
                            month: this.formData.month
                       }
                   })
               }
           },
            //付款单位
            companyNameChangeEvent(item, nameType) {
                //console.log(item,nameType)
                this.formData.company_id = item.id;
                if (nameType == 'customer_name') {
                    this.formData.company_name = item.customer_name;
               } else if (nameType == 'supplier_name') {
                    this.formData.company_name = item.supplier_name;
               }
                this.$_searchWarehouseBillByKdOrJc(this.formData.company_name).then(() => {
                    /*                        this.getPaidUp();
                        this.getTotalAmount();
                    this.getAllocatedAmount();
                    this.getDispensAbility();*/
                    this.radioEvent(this.formData.cash_type);
               })
           },

            _searchWarehouseBillByKdOrJcEvent() {
                if (this.formData.allocated_amount != 0) {
                    this.$XModal.confirm('保存后，将清空“结算金额”？').then(type => {
                        //const $grid = this.$refs.xGrid
                        if (type === 'confirm') {
                            this.$_searchWarehouseBillByKdOrJc(this.formData.company_name);
                       }
                   })
               } else {
                    this.$_searchWarehouseBillByKdOrJc(this.formData.company_name);
               }
           },
            _createRowFooterDataByReceipt(list, cashType) {
                if (list == null) {
                    list = [];
               }
                for (var index = list.length; index < 30; index++) {
                    list.push({
                        id: '',
                        company_name_assist : '',
                        company_uuid_assist : '',
                        subject_id_cash_data: '',
                        subject_name_cash_data : '',
                        money_cash_data : '',
                        remark: '',
                        cash_id: '',//对应出纳单据编号
                        cash_type : cashType,//
                        amount: '',//结算金额
                        last_date_cash_data : '',//最后修改日期
                        last_name_cash_data : '',//最后修改人
                        registrar_cash_data : '',//记录人
                        company_name : '',//供应商、客户
                        check_number : '',//支票号码
                        draw_company : '',//出票单位
                        order_id : index + 1,
                        operateVisible : false,//是否显示操作弹层
                   })
               }
                return list;
           },
            //单击
            cellClickEventMain({row, column}) {
                if (column.property == 'check_number') {
                    const $table = this.$refs.CashDataxGrid
                    // 重写默认的覆盖式，改为追加式
                    $table.setActiveCell(row, column)
               }
                this.rowMain = row;
           },

            //一般费用单，单击单元，把改行的值赋予到对应的row中
            cellClickEventModal({row, column}) {
                if (column.property == 'kick_back_total_balance' || column.property == 'pounds_fees_balance' || column.property == 'wind_cut_fees_balance' ||
                    column.property == 'loading_car_fees_balance' || column.property == 'transfer_fees_balance' || column.property == 'freight_fees_balance'
                    || column.property == 'taxes_fees_balance' || column.property == 'other_freight_fees_balance' || column.property == 'procedure_fees_balance') {
                    let title = column.title.substring(0, column.title.indexOf("("));
                    //this.rowMain.cash_data_remark = column.title
                    this.rowMain.fees_name = title;
                    this.rowMain.money_cash_data = row[column.property];
                    this.rowMain.bill_id_kd_or_jc = row.b_id;
                    this.rowMain.company_name = row.company_name;
                    this.rowMain.sales_man = row.sales_man;
                    this.rowMain.company_uuid = row.company_uuid;
               }
                //更新金额
                this.getPaidUp();
                this.getTotalAmount();
                this.getAllocatedAmount();
                this.getDispensAbility();
                this.showModel.isShow = false;
           },
            //单击
            cellClickEventFooter({row, column}) {
                if (column.property == 'check_number' || column.property == 'check_type') {
                    const $table = this.$refs.xGridFooter
                    // 重写默认的覆盖式，改为追加式
                    $table.setActiveCell(row, column)
                }
                /*if (this.$refs.xGridFooter.isCheckedByCheckboxRow(row)){
                    this.$refs.xGridFooter.setCheckboxRow([this.footerData[rowIndex]], false)
                }else{
                    this.$refs.xGridFooter.setCheckboxRow([this.footerData[rowIndex]], true)
                }*/
           },
            //双击勾单
            cellClickEvent({row, column}) {
                if (column.property == 'isOk') {
                    //判断可分配金额是否大于0，
                    //如果可分配金额大于等于余额，结算金额 = 金额   结算金额 + 余额
                    //如果可分配金额小于余额，结算金额 = 结算金额 + 可分配金额
                    //this.$XModal.alert({content: row.cash_type, maskClosable: true})
                    if ((row.cash_type == 'SKD' || row.cash_type == 'FKD') && row.bill_id_kd_or_jc.indexOf("QC") == -1 ) {
                        //this.$XModal.alert({content: JSON.stringify(row), maskClosable: true})
                        /*if (row.balance < 0) {
                            //余额负数，说明挂账，请到挂账单号上操作。
                            this.$XModal.alert({content: '余额负数，说明挂账，请到挂账单号上操作！', maskClosable: true})
                       } else */
                       /* if (this.formData.dispens_ability > 0) {

                       } else {
                            this.$XModal.alert({content: '可分配余额不足！', maskClosable: true})
                       }*/
                        this.setAmount(row);
                   } else {
                        this.setAmount(row);
                   }

                    //console.log(row, column)
               }else  if (column.property == 'bill_id_kd_or_jc') {
                    if("SDJ" == row.cash_type){
                        this.$_openType({path: '/receipt', query: {id: row.bill_id_kd_or_jc}})
                   }else if (!this.isBlankVue(row.bill_id_kd_or_jc) && row.bill_id_kd_or_jc.startsWith('KD')){
                        this.$_openType({path: '/transferSlip', query: {bill_id_kd : row.bill_id_kd_or_jc}})
                   }else if(!this.isBlankVue(row.bill_id_kd_or_jc) && row.bill_id_kd_or_jc.startsWith('JC')){
                        this.$_openType({path: '/intoTheWarehouse', query: {bill_id: row.bill_id_kd_or_jc}})
                        //intoTheWarehouse?billID=JC2022-06-04-0014&wb_id=18994
                   }
                    //console.log(row);

               }
           },
            setAmount(row) {
                //amount 结算金额
                //balance 余额
                //dispensAbility 可分配金额

                //可分配金额 大于 未结余额
                if (this.formData.dispens_ability > 0 && this.formData.dispens_ability >= row.balance) {
                    //结算金额 = （已输入）结算金额 + 未结余额
                    row.amount = this.$XEUtils.add(row.amount, row.balance);
                    //row.balance = 0;
               } else if (this.formData.dispens_ability > 0) {
                    //可分配金额 大于0，
                    //结算金额 = （已输入）结算金额 + 可分配金额
                    //row.balance = this.$XEUtils.subtract(row.balance, this.getDispensAbility);
                    row.amount = this.$XEUtils.add(row.amount, this.formData.dispens_ability);
               }else if(this.formData.dispens_ability < 0 && row.balance < 0 ){
                    //console.log('负数')
                    //row.amount = 0;
                    if(this.formData.dispens_ability >= row.balance){
                        //console.log('大于');
                        //console.log(row.balance);
                        row.amount = this.$XEUtils.add(row.amount, this.formData.dispens_ability);
                   }else{
                        //console.log('少于');
                        //console.log(row.balance)
                        row.amount = this.$XEUtils.add(row.amount, row.balance);
                   }

               }
                this.getPaidUp();
                this.getTotalAmount();
                this.getAllocatedAmount();
                this.getDispensAbility();
                //row.amount = Number(row.amount);
                this.setBalance(row);//设置 确认状态，判断是否需要打勾
           },
            //设置 确认状态，判断是否需要打勾
            setBalance(row) {
                if (this.formData.cash_type == 'SKD' || this.formData.cash_type == 'FKD') {
                    if (this.isNumberZeroVue(row.amount)) {
                        //row.balance = Number(0);
                        //console.log("row.balance",row.balance)
                        //要判断一下，这个 已支付 是否包含了 结算金额，如果是的话，则只减 已支付 即可
                        //金额 - 结算金额 = 余额
                        //余额 - 其他单已结算金额 = 真正的余额
                        row.balance = this.$XEUtils.subtract(this.$XEUtils.subtract(row.total_amount, row.amount), row.amount_other);
                        //本单挂数金额
                        if (row.amount > 0){
                            //大于0才会有挂数金额
                            if(this.$XEUtils.subtract(row.total_amount, row.amount_other) < 0 ){
                                //如果 金额 - 其他单据  等于负数，那么录入的结算金额 = 挂数金额
                                row.hang_money_this = row.amount;
                           }else if (row.balance < 0){
                                row.hang_money_this = this.$XEUtils.subtract(0,row.balance);
                           }
                       }else{
                            row.hang_money_this = 0;
                       }
                        if (row.balance == 0) {
                            row.isOk = '✓'
                       } else {
                            row.isOk = ''
                       }
                   }
                    row.payend = this.$XEUtils.add(row.amount, row.amount_other);
               }
           },

            pageChangeEvent({currentPage, pageSize}) {
                this.dropDownTablePage.currentPage = currentPage
                this.dropDownTablePage.pageSize = pageSize
                this.$_searchSubjectList();
                // const activeRow = this.$refs.baseGird.getActiveRecord().row;
                //this.searchJCWarehouse(activeRow.specification);
           },
            /**
             * 选中科目编码下拉，用于event {row}转row
             * @param row
             */
            radioChangeEvent({row}, searchSub, dropDownGrid, mainGridRef, vxePulldown, xDownSubjectIdIsShow,gridName) {
                this.radioChangeEnterEvent(row, searchSub, dropDownGrid, mainGridRef, vxePulldown, xDownSubjectIdIsShow,gridName)
           },
            radioChangeEnterEvent(row, searchSub, dropDownGrid, mainGridRef, vxePulldown, xDownSubjectIdIsShow,gridName) {
                if (row != null) {
                    this.$_isHasFatherId(row, searchSub, dropDownGrid, mainGridRef, vxePulldown, xDownSubjectIdIsShow,gridName)
                    //row.id 查询判断是否有子类，如果有的话，继续查询
               }
           },
            radioSummaryChangeEvent({row},mainGridRef) {
                //console.log('row: ' + row);
                //console.log('mainGridRef: ' + mainGridRef);
                this.radioSummaryChangeEnterEvent(row,mainGridRef);
           },
            radioSummaryChangeEnterEvent(row,mainGridRef) {
                //console.log('radioSummaryChangeEnterEvent',row);
                if (mainGridRef == 'xDownSummary'){
                    if (row != null) {
                        const activeRow = this.$refs.CashDataxGrid.getActiveRecord().row;
                        activeRow.summary_cash_data = row.summary_name;
                   }
                    this.$refs['xDownSummary'].hidePanel();
                    this.xDownSummaryIsShow = false
               }else if(mainGridRef == 'xDownSummaryFooter'){
                    if (row != null) {
                        const activeRow = this.$refs.xGridFooter.getActiveRecord().row;
                        activeRow.summary_cash_data = row.summary_name;
                   }
                    this.$refs['xDownSummaryFooter'].hidePanel();
                    this.xDownSummaryIsShowFooter = false
               }

           },
            radioSummaryChangeEventFooter({row}) {
                this.radioSummaryChangeEnterEventFooter(row);
           },
            radioSummaryChangeEnterEventFooter(row) {
                if (row != null) {
                    const activeRow = this.$refs.xGridFooter.getActiveRecord().row;
                    activeRow.summary_cash_data = row.summary_name;
               }
                this.$refs['xDownSummaryFooter'].hidePanel();
                this.xDownSummaryIsShowFooter = false
           },
            /**
             * 选中摘要下拉 CashDataxGrid
             * @param row
             */
            pageChangeSummaryEvent({currentPage, pageSize}) {
                this.dropDownTablePageSummary.currentPage = currentPage
                this.dropDownTablePageSummary.pageSize = pageSize
                const activeRow = this.$refs.CashDataxGrid.getActiveRecord().row;
                this.$_searchSummaryList(activeRow.summary_cash_data);
           },

            editMethod({row, column}) {
                const $table = this.$refs.CashDataxGrid
                // 重写默认的覆盖式，改为追加式
                $table.setActiveCell(row, column)
           },
            editMethodFooter({row, column}) {
                const $table = this.$refs.xGridFooter
                // 重写默认的覆盖式，改为追加式
                $table.setActiveCell(row, column)
           },
            //出纳 判断该科目是否存在子类
            //作废，准备抽取共用
            /*$_isHasFatherId(row, searchSub, dropDownGridRef, mainGridRef, vxePulldown, xDownSubjectIdIsShow) {
                //console.log('1xDownSubjectIdIsShow',xDownSubjectIdIsShow);
                //console.log('1this.$refs[xDownSubjectIdIsShow]',this.$data[xDownSubjectIdIsShow]);
                this.$axios({
                    method: 'post',
                    url: '/admin/subject/isHasFatherId',
                    params: {
                        keyword: row.id_subject
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200 && response.data.state == "ok") {
                        //如果有子类，则继续再查询
                        this.$data[xDownSubjectIdIsShow] = true;
                        this.$_searchSubjectList(row.id_subject, searchSub)
                   } else {
                        //console.log('mainGridRef',mainGridRef)
                        //console.log('dropDownGridRef',dropDownGridRef)
                        //console.log('2xDownSubjectIdIsShow',xDownSubjectIdIsShow)
                        //console.log('2xDownSubjectIdIsShow',this.$data[xDownSubjectIdIsShow])
                        const activeRow = this.$refs[mainGridRef].getActiveRecord().row;
                        //console.log('activeRow: ', activeRow);
                        activeRow.subject_id_cash_data = row.id_subject;
                        activeRow.subject_name_cash_data = row.subject_name;
                        this.$data[xDownSubjectIdIsShow] = false;
                        //console.log('2this.$refs[xDownSubjectIdIsShow]',this.$data[xDownSubjectIdIsShow])
                        this.$refs[vxePulldown].hidePanel();
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },*/
            //（下拉主数据 用于设置上下选择行的位置）、事件、弹框的GridRef、赋值GridRef、下拉框是否显示
            keyUpDropDownEvent(dropDownTableData, event, dropDownGridRef, mainGridRef, vxePulldown, xDownSubjectIdIsShow) {
                //console.log(xDown);
                //console.log('this.$refs[xDown].isPanelVisible()', this.$refs[xDown].isPanelVisible());
                ////console.log(dropDownGrid,this.$refs[dropDownGrid],dropDownTableData)
                //console.log('event.$event.code',event.$event.code)
                let i = 0;
                if (event.$event.code == 'Enter' || event.$event.code == 'NumpadEnter') {
                    //如果有内容，则回车默认选中第一个值
                    if (dropDownTableData.length > 0 && this.isBlankVue(this.$refs[dropDownGridRef].getRadioRecord())){
                        this.$refs[dropDownGridRef].setRadioRow(dropDownTableData[0]);
                        this.$refs[dropDownGridRef].scrollToRow(dropDownTableData[0]);
                   }else if (dropDownGridRef == 'dropDownGrid' && dropDownTableData.length == 0 ){
                        //科目编码 没有内容，则收起下拉框
                        this.$refs['xDownSubjectId'].hidePanel();
                        this.xDownSubjectIdIsShow = false;
                   }
                    i = this.$refs[dropDownGridRef].getRowIndex(this.$refs[dropDownGridRef].getRadioRecord());
                    //摘要
                    //console.log('dropDownGridRef' + dropDownGridRef);
                    if (dropDownGridRef == 'dropDownGridSummary') {
                        //console.log('dropDownGridSummary');
                        this.radioSummaryChangeEnterEvent(this.$refs[dropDownGridRef].getRadioRecord(),mainGridRef);
                        this.$refs['xDownSummary'].hidePanel();
                        this.xDownSummaryIsShow = false;
                   }else if(dropDownGridRef == 'dropDownGridSummaryFooter'){
                        //console.log('dropDownGridSummaryFooter');
                        this.radioSummaryChangeEnterEvent(this.$refs[dropDownGridRef].getRadioRecord(),mainGridRef);
                        this.$refs['xDownSummaryFooter'].hidePanel();
                        this.xDownSummaryIsShowFooter = false;
                   }
                    if (dropDownGridRef == 'dropDownGrid' || dropDownGridRef == 'dropDownGridFooter') {
                        //回车才出发是否继续查询有没有子类
                        this.radioChangeEnterEvent(this.$refs[dropDownGridRef].getRadioRecord(), true, dropDownGridRef, mainGridRef, vxePulldown, xDownSubjectIdIsShow);
                   }
                    //console.log('Enter', i);
               } else if (event.$event.code == 'ArrowUp' || event.$event.code == 'ArrowDown') {
                    //console.log("执行上下箭头", event.$event.code);
                    if (this.$refs[dropDownGridRef].getRadioRecord() != null) {
                        i = this.$refs[dropDownGridRef].getRowIndex(this.$refs[dropDownGridRef].getRadioRecord());
                   }
                    if (event.$event.code == 'ArrowUp') {
                        if (i > 0) {
                            i = i - 1;
                       }
                   } else if (event.$event.code == 'ArrowDown') {
                        ////console.log('dropDownTableData.length',dropDownTableData.length)
                        if (this.$refs[dropDownGridRef].getRadioRecord() == null) {
                            i = 0;
                       } else {
                            i = i + 1;
                       }
                   }
                    ////console.log("dropDownTableData: " + dropDownTableData)
                    this.$refs[dropDownGridRef].setRadioRow(dropDownTableData[i]);
                    this.$refs[dropDownGridRef].scrollToRow(dropDownTableData[i])
               }
                ////console.log('this.$refs[dropDownGrid]', this.$refs[dropDownGrid])
                if (i != 0) {
                    this.$refs[dropDownGridRef].setRadioRow(dropDownTableData[i]);
                    this.$refs[dropDownGridRef].scrollToRow(dropDownTableData[i])
               } else {
                    this.$refs[dropDownGridRef].setRadioRow(dropDownTableData[0]);
                    this.$refs[dropDownGridRef].scrollToRow(dropDownTableData[0])
               }
           },
            //如果为空，则自动添加：当天日期
            cashDateBlur() {
                if (this.isBlankVue(this.formData.cash_date)) {
                    this.formData.cash_date = new Date();
               }
           },
            formatDate(value, format) {
                return XEUtils.toDateString(value, format)
           },
            tableRowClassName({rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'success-row';
               } else {
                    return '';
               }
           },
            _reMakeFooterData(footerData, cashType) {
                for (var t in footerData) {
                    if (!this.isNumberVue(footerData[t].amount)) {
                        footerData[t].amount = 0;
                        footerData[t].isOk = '';
                        footerData[t].cash_type = cashType;
                   }
               }
                return footerData;
           },
            consoleEvent() {
                //console.log('test hide-panel')
           },
            //查询收款单
            async searchReceipt() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                await this.$axios({
                    method: 'post',
                    url: '/admin/receipt/searchReceipt',
                    params: {
                        id: this.formData.id,
                        cash_type_id : this.formData.cash_type_id,
                        type: this.formData.type,
                        year: this.formData.year,
                        month: this.formData.month
                   },
               }).then((response) => {          //这里使用了ES6的语法
                    ////console.log(response)       //请求成功返回的数据
                    loading.close();
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.formData = response.data.formData;
                        this.footerData = [];
                        this.changeRadioEvent();
                        this.tableDataMain = this.$_createRowDataByReceipt(response.data.tableDataMain);
                        this.$_searchWarehouseBillByKdOrJc(this.formData.company_name).then(() => {
                            if (this.formData.cash_type == 'ZJRZ' || this.formData.cash_type == 'QTSRD' || this.formData.cash_type == 'YBFYD') {
                                this.footerData = this._createRowFooterDataByReceipt(response.data.footerData, this.formData.cash_type);
                                //this.$XModal.alert(this.footerData)
                                //console.log('this.footerData',this.footerData)
                           }
                       });
                        //console.log('v')
                        //读取单据状态
                        if (!this.isBlankVue(this.formData.voucher_id)){
                            this.titleList=[]
                            this.titleList.push('已生成凭证');
                        }
                        if (this.formData.cash_type == 'SKD' || this.formData.cash_type == 'FKD') {
                            this.footerData = this._reMakeFooterData(response.data.footerData, this.formData.cash_type);
                       }
                   } else {
                        //this.$XModal.alert(response.data.msg)
                        this.disableGrid = true;
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                   }

               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
                //return Promise.resolve();
           },
            clearSujectEvent(row) {
                row.subject_id = '',//科目编码
                row.subject_name = '',//科目名称
                    //row.subject_id_and_name = ''//科目编码&科目名称
                row.company_name_assist = ''//辅助项
                row.company_uuid_assist = ''//辅助项
           },
            //切换底部列字段
            changeRadioEvent() {
                //this.$XModal.message({ content: this.formData.cash_type, status: 'success'})
                if (this.formData.cash_type == 'SKD' || this.formData.cash_type == 'FKD') {
                    this.footerTableColumn = this.footerTableColumnSKD;
               } else if (this.formData.cash_type == 'ZJRZ' || this.formData.cash_type == 'QTSRD' || this.formData.cash_type == 'YBFYD') {
                    this.footerTableColumn = this.footerTableColumnZJRZorQTSRDorYBFYD;
               } else if (this.formData.cash_type == 'SDJ' || this.formData.cash_type == 'YFK') {
                    this.footerTableColumn = this.footerTableColumnSDJ;
               }
               if (this.formData.cash_type == 'YBFYD') {
                    this.tableColumn = this.tableColumnYBFYD;
               }

            },
            //选择不同的类型
            radioEvent(e) {
                if (this.formData.allocated_amount != 0) {
                    this.$XModal.confirm('保存后，将清空“结算金额”？').then(type => {
                        //const $grid = this.$refs.xGrid
                        if (type === 'confirm') {
                            this.radioEventDoAction(e);
                       }
                   })
               } else if (!this.isBlankVue(this.formData.id)) {
                    this.$XModal.confirm('切换其他类型，保存后，将清空“结算金额”？').then(type => {
                        //const $grid = this.$refs.xGrid
                        if (type === 'confirm') {
                            this.radioEventDoAction(e);
                       }
                   })
               } else {
                    this.footerData = [];
                    this.radioEventDoAction(e);
               }
           },
            newBuild(){
                if (this.$_isGridDataChange(this.$refs['CashDataxGrid']) || this.$_isGridDataChange(this.$refs['xGridFooter']) || this.isDataChanage) {
                    this.$message({
                        showClose: true,
                        message: this.mustSaveMsg,
                        type: 'error'
                    });
                }else{
                    this.$router.push({path: '/receipt', query: {type:this.formData.type,cash_date:this.$XEUtils.toDateString(this.formData.cash_date, 'yyyy-MM-dd')}})
                }
            },
            onSubmit() {
                this.loading = true
                let isSave = false;
                if(this.isBlankVue(this.formData.id)){
                    isSave = true;
                }
                let loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.isDataChanage = false;
                this.$axios({
                    method: 'post',
                    url: '/admin/receipt/saveOrUpdateReceipt',
                    data: {
                        formData: this.formData,
                        tableDataMain: this.tableDataMain,
                        footerData: this.footerData
                   },
               }).then((response) => {          //这里使用了ES6的语法
                    loading.close();
                    this.loading = false
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '保存成功',
                            type: 'success'
                       });
                        this.footerData = response.data.footerData;
                        this.formData = response.data.formData;
                        this.tableDataMain = response.data.tableDataMain;
                        //this.formData = response.data.warehouseBill;
                        //this.tableDataMain = this.$_createRowDataByReceipt(response.data.stocks);
                        if(isSave){
                            this.$router.push({
                                path: "/receipt",
                                query: {
                                    cash_type_id : this.formData.cash_type_id,
                                    type: this.formData.type,
                                    year: this.formData.year,
                                    month: this.formData.month
                                }
                            })
                        }
                        //this.searchReceipt();
                   } else {
                        this.loading = false
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    this.loading = false
                    console.log(error)
                    loading.close();
                    this.$message({
                        showClose: true,
                        message: '网络超时，请重试！',
                        type: 'error'
                   });
               });

           },
            //自动分配
            automatic() {
                //重新进行分配
                for (var c in this.footerData) {
                    this.setAmount(this.footerData[c]);
               }
           },
            //合计冲账金额
            getTotalBalanceMoney() {
                let totalBalanceMoney = Number(0);
                for (var t in this.tableDataMain) {
                    if (this.isNumberVue(this.tableDataMain[t].money_cash_data)) {
                        totalBalanceMoney = this.$XEUtils.add(totalBalanceMoney, Number(0 - this.tableDataMain[t].balance_money));
                   }
               }
                this.formData.total_balance_money = Number(totalBalanceMoney) ? Number(totalBalanceMoney) : 0;
           },
            //合计实收
            getPaidUp() {
                //console.log('getTotalAmount 合计金额');
                let paidUp = Number(0);
                for (var t in this.tableDataMain) {
                    if (this.isNumberVue(this.tableDataMain[t].money_cash_data)) {
                        paidUp = this.$XEUtils.add(paidUp, Number(this.tableDataMain[t].money_cash_data));
                        paidUp = this.$XEUtils.subtract(paidUp, Number(0 - this.tableDataMain[t].balance_money));
                   }
               }
                /*for (var f in this.footerData) {
                    //如果底部不为空，说明合计的时候需要合计起来，用来计算预付、已收，付了多少钱
                    if (this.isNumberVue(this.footerData[f].amount) && (this.footerData[f].cash_type == 'SDJ' || this.footerData[f].cash_type == 'YFK')) {
                        if (Number(this.footerData[f].amount) > 0) {
                            paidUp = this.$XEUtils.subtract(paidUp, Number(this.footerData[f].amount));
                       } else {
                            paidUp = this.$XEUtils.add(paidUp, this.$XEUtils.subtract(0, Number(this.footerData[f].amount)));
                       }
                   }
               }*/
                //遍历底部，判断是否FKD，是的话则判断金额是否存在，存在的话合计进去，
                this.formData.paid_up = Number(paidUp) ? Number(paidUp) : 0;
                this.formData.balance = Number(paidUp) ? Number(paidUp) : 0;
           },
//合计  金额+冲账金额 + 结算金额 = 合计金额
            getTotalAmount() {
                let totalAmount = Number(0);
                for (var t in this.tableDataMain) {
                    if (this.isNumberVue(this.tableDataMain[t].money_cash_data)) {
                        totalAmount = this.$XEUtils.add(totalAmount, Number(this.tableDataMain[t].money_cash_data));
                   }
               }
                this.formData.total_amount = Number(totalAmount) ? Number(totalAmount) : 0;
           },
            //已分配金额
            getAllocatedAmount() {
                //console.log('getAllocatedAmount', this.footerData);
                let allocatedAmount = Number(0);
                for (var t in this.footerData) {
                    //console.log('getAllocatedAmount',this.footerData[t].amount)
                    //console.log(this.footerData[t]);
                    //&& (this.footerData[t].cash_type == 'SKD' || this.formData.cash_type == 'ZJRZ' || this.formData.cash_type == 'QTSRD' || this.formData.cash_type == 'YBFYD' || this.formData.cash_type == 'FKD')
                    if (this.isNumberVue(this.footerData[t].amount)) {
                        allocatedAmount = this.$XEUtils.add(allocatedAmount, Number(this.footerData[t].amount));
                   }
               }
                this.formData.allocated_amount = Number(allocatedAmount) ? this.$XEUtils.round(Number(allocatedAmount), 2) : 0;
           },
            //可分配金额(数据库不需要这个字段，该字段等于未结算金额)
            getDispensAbility() {
                //return this.getAllocatedAmount;
                this.formData.dispens_ability = this.$XEUtils.subtract(Number(this.formData.paid_up), Number(this.formData.allocated_amount));
           },
            //计算挂数金额
            getHangMoney() {
                let money = Number(0);
                for (var t in this.footerData) {
                    //console.log(this.footerData[t].total_amount)
                    //console.log(this.footerData[t].amount)
                    //console.log(this.footerData[t]);
                    if ((this.footerData[t].cash_type == 'SKD' || this.footerData[t].cash_type == 'FKD') && !this.isBlankVue(this.footerData[t].amount) ) {
                        money = this.$XEUtils.add(money, Number(this.footerData[t].hang_money_this));
                   }
               }
                this.formData.hang_money = Number(money) ? this.$XEUtils.round(Number(money), 2) : 0;
           },
            radioEventDoAction(e) {
                this.formData.cash_type = e;
                this.changeRadioEvent();
                if (this.formData.cash_type == 'ZJRZ' || this.formData.cash_type == 'QTSRD' || this.formData.cash_type == 'YBFYD') {
                    if (this.footerData.length == 0) {
                        this.footerData = this._createRowFooterDataByReceipt(this.footerData, this.formData.cash_type);
                   }
               } else {
                    this.$_searchWarehouseBillByKdOrJc(this.formData.company_name).then(() => {
                        this.getPaidUp();
                        this.getTotalAmount();
                        this.getAllocatedAmount();
                        this.getDispensAbility();
                   })
                    if (this.formData.cash_type == 'SKD' || this.formData.cash_type == 'FKD') {
                        this.footerData = this._reMakeFooterData(this.footerData, this.formData.cash_type);
                   }
               }
           },
            checkboxChangeEvent(){
                let items = this.$refs.xGridFooter.getCheckboxRecords();
                let total_balance_check = 0;
                for (const item of items) {
                    total_balance_check = this.$XEUtils.add(item.balance,total_balance_check);
                }
                this.total_balance_check = total_balance_check;
            }
       }, computed: {
            isShowSKD: function () {
                if (this.formData.type == 'SKD') {
                    return true
               } else {
                    return false
               }
           },
            isShowQTSRD: function () {
                if (this.formData.type == 'QTSRD') {
                    return true
               } else {
                    return false
               }
           },
            isShowYBFYD: function () {
                if (this.formData.type == 'YBFYD') {
                    return true
               } else {
                    return false
               }
           },
            isShowFKD: function () {
                if (this.formData.type == 'FKD') {
                    return true
               } else {
                    return false
               }
           },
            isZJRZ: function () {
                if (this.formData.cash_type == 'ZJRZ') {
                    return true
               } else {
                    return false
               }
           },

            getTitle: function () {
                if (this.formData.cash_type == 'ZPRZ') {
                    return '当前：支票入账'
               } else if (this.formData.cash_type == 'ZJRZ') {
                    return '当前：资金入账'
               } else if (this.formData.cash_type == 'SDJ') {
                    return '当前：收订金'
               } else if (this.formData.cash_type == 'SKD') {
                    return '当前：收款单'
               } else if (this.formData.cash_type == 'FKD') {
                    return '当前：付款单'
               } else if (this.formData.cash_type == 'YFK') {
                    return '当前：预付款'
               } else if (this.formData.cash_type == 'QTSRD') {
                    return '当前：其他收入单'
               } else if (this.formData.cash_type == 'YBFYD') {
                    return '当前：一般费用单'
               } else {
                    return '当前：未知'
               }
           },


       },
        created() {
            this.loading = true
            this.$_searchCustomerList('');
            this.$_searchSupplierList('');
            this.$_searchSummaryList('');//查询摘要
            this.$_searchAccountList('');//经手人 全部用户
            var list = [];
            this.tableDataMain = this.$_createRowDataByReceipt(list);

            /**
             *
             * 判断是否打开已有单据
             *
             * @type {string | (string | null)[]}
             */

            this.formData.cash_type_id = this.$route.query.cash_type_id;
            this.formData.id = this.$route.query.id
            this.formData.type = this.$route.query.type
            this.formData.year = this.$route.query.year
            this.formData.month = this.$route.query.month
            if (!this.isBlankVue(this.$route.query.cash_date)) {
                this.formData.cash_date = this.$route.query.cash_date
           }
            if (!this.isBlankVue(this.$route.query.type)) {
                this.radioEvent(this.$route.query.type)
           }
            if (this.isBlankVue(this.$route.query.cash_type_id) && this.isBlankVue(this.$route.query.id)) {
                this.formData.registrar = this.$store.getters.getLoginAccount.nick_name;
                //如果是付款单，设置默认选择付款单类型中的付款单
                if (this.formData.type == 'FKD') {
                    this.formData.type = 'FKD';
                    this.formData.cash_type = 'FKD';
               } else if (this.formData.type == 'QTSRD') {
                    this.formData.type = 'QTSRD'
                    this.formData.cash_type = 'QTSRD'
               } else if (this.formData.type == 'YBFYD') {
                    this.formData.type = 'YBFYD'
                    this.formData.cash_type = 'YBFYD'
               } else {
                    this.formData.type = 'SKD'
                    this.formData.cash_type = 'SKD'
               }

                /*if(this.isBlankVue(this.formData.year_month)) {
                    this.formData.year_month = this.formData.cash_date

               }*/
                this.changeYearMonthEvent();
           } else {
                this.openCashTypeId = this.formData.cash_type_id;//用于上一页，下一页
                this.searchReceipt().then(() => {
                    setTimeout(() => {
                        this.getPaidUp();
                        this.getTotalAmount();
                        this.getAllocatedAmount();
                        this.getDispensAbility();
                        //this.formData.year_month = this.formData.cash_date;
                        this.changeYearMonthEvent();

                   }, 1000)

               });
           }

            //this.$_getAccountListByRoleId('16');//经手人 销售
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.CashDataxGrid.connect(this.$refs.xToolbar1)
                this.$refs.xGridFooter.connect(this.$refs.xToolbar2)
                this.changeRadioEvent();
                if (this.formData.cash_type == 'QTSRD') {
                    this.$refs['CashDataxGrid'].showColumn(this.$refs['CashDataxGrid'].getColumnByField('company_name'))
                }
                this.loading = false
           })
       }
   };


</script>

<style scoped>

    /deep/ table .el-input--mini .el-input__inner, table .el-input-number--mini {
        height: 28px;
        line-height: 28px;
   }

    /deep/ .el-input-group__prepend {
        border: initial;
   }

    /deep/ #openCashTypeId > div > .el-input-group__prepend {
        padding: 0 0px
   }

    /*修改行高*/
    /deep/ .vxe-table--render-default.size--mini .vxe-body--column.col--ellipsis, .vxe-table--render-default.size--mini .vxe-footer--column.col--ellipsis, .vxe-table--render-default.size--mini .vxe-header--column.col--ellipsis, .vxe-table--render-default.vxe-editable.size--mini .vxe-body--column{
        height: 24px !important;
   }
    /deep/ .vxe-table--render-default.size--mini .vxe-cell .vxe-default-input, .vxe-table--render-default.size--mini .vxe-cell .vxe-default-select, .vxe-table--render-default.size--mini .vxe-cell .vxe-default-textarea{
        height: 24px !important;
   }
    /deep/ .vxe-input--clear-icon.vxe-icon--close {
        height: 1.2em !important;
   }
    /deep/ .vxe-input.size--mini {
        height: 24px !important;
   }
    /deep/ table .el-input__inner {
        height: 22px !important;
        line-height: 24px !important;
   }
    /deep/ .vxe-table--render-default .vxe-body--column, .vxe-table--render-default .vxe-footer--column, .vxe-table--render-default .vxe-header--column {
        line-height: 20px !important;
   }
    /deep/ .vxe-button.size--mini.type--button.is--circle {
        min-width: 22px !important;
   }
    /deep/ .vxe-button.size--mini.type--button {
        height: 22px !important;
   }
    /deep/ .vxe-button.size--mini{
        font-size: 10px !important;
   }
    /deep/.el-input-group__append, .el-input-group__prepend{
        padding: 0 10px !important;
    }
    /*修改行高*/
</style>
